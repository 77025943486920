import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_B2C_SIGNUP_SIGNIN!,
    forgotPassword: process.env.REACT_APP_B2C_FORGOT_PASSWORD!,
    editProfile: process.env.REACT_APP_B2C_EDIT_PROFILE!,
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_B2C_SIGNUP_SIGNIN_AUTHORITY!,
    },
    forgotPassword: {
      authority: process.env.REACT_APP_B2C_FORGOT_PASSWORD_AUTHORITY!,
    },
    editProfile: {
      authority: process.env.REACT_APP_B2C_EDIT_PROFILE_AUTHORITY!,
    },
  },
  authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN!,
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID!,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: process.env.REACT_APP_REDIRECT_URI || "/",
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI || "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: process.env.REACT_APP_CACHE_LOCATION || "sessionStorage",
    storeAuthStateInCookie: process.env.REACT_APP_STORE_AUTH_STATE_IN_COOKIE === "true",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [], 
  prompt: "login", 
};

export const silentRequest = {
  scopes: ["openid", "profile"],
  loginHint: "example@domain.net",
};
