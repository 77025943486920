// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  font-family: "Poppins" !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  margin-top: 20vh;
  max-height: 35vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1rem);
  color: #3c4f1e;
  font-family: "Poppins";
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 600px) {
  .css-hyum1k-MuiToolbar-root {
    min-height: 50px !important;
  }
  .css-ymzihv {
    min-height: 50px !important;
  }
}

.title {
  font-size: 20px;
  color: #333;
  margin-top: 0;
  margin-bottom: 17px;
}

.subtitle {
  font-size: 10px;
  color: #777;
}

/* Overlay.css */
.overlay {
  position: absolute;
  top: 115px;
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1;
}

.filter-top-left {
  position: absolute;
  top: 50px;/*113px;*/
  margin-left: calc(18vw + 2px);
  z-index: 10;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,4BAA4B;EAC5B,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,eAAe;EACf,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA,gBAAgB;AAChB;EACE,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,oCAAoC,EAAE,2BAA2B;EACjE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,SAAS,CAAC,SAAS;EACnB,6BAA6B;EAC7B,WAAW;AACb","sourcesContent":[".App {\n  text-align: center;\n  font-family: \"Poppins\" !important;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.App-header {\n  margin-top: 20vh;\n  max-height: 35vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 1rem);\n  color: #3c4f1e;\n  font-family: \"Poppins\";\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n@media (min-width: 600px) {\n  .css-hyum1k-MuiToolbar-root {\n    min-height: 50px !important;\n  }\n  .css-ymzihv {\n    min-height: 50px !important;\n  }\n}\n\n.title {\n  font-size: 20px;\n  color: #333;\n  margin-top: 0;\n  margin-bottom: 17px;\n}\n\n.subtitle {\n  font-size: 10px;\n  color: #777;\n}\n\n/* Overlay.css */\n.overlay {\n  position: absolute;\n  top: 115px;\n  margin-left: 10px;\n  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */\n  z-index: 1;\n}\n\n.filter-top-left {\n  position: absolute;\n  top: 50px;/*113px;*/\n  margin-left: calc(18vw + 2px);\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
