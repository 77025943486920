import React, { useState, useEffect, useCallback, useMemo  } from "react";
import {
  Grid,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  MenuItem,
  Chip,
} from "@mui/material";
import { useForm, Controller, useWatch } from "react-hook-form";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Delete from "@mui/icons-material/Delete";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useConfig } from "../../context/ConfigContextTest";
import { getCultivars } from "../../services/api-car-service";
import DynamicFormDialog from "../molecules/dialog";
import TextBox from "../atom/textBox";
import MultiSelect from "../molecules/MultiSelect";
import DateSelector from "../atom/dateSelect";
import ActivityNotesDialog from "./ActivityNotesDialog";
import ActivityProductsDialog from "./ActivityProductsDialog";
import DynamicFieldRenderer from "../molecules/DynamicFieldRenderer";
import ProductTable from "./ProductTable";
import theme from "../../theme";

import { getOrganizationFarms } from "../../services/api-ffm-service";

import { getLinkedFields } from "../../services/api-gs-service"; 

interface FormData {
  activityId: number;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  fields: number[];
  cost: string;
  contractWorkCost: string;
  Properties: any;
  activityCategoryId: number;
  activityStatusId: number;
  seasonStageId: number;
  notes: Array<{
    ActivityNoteId?: number;
    title: string;
    description: string;
    document: {
      documentId: string;
      documentTypeId: number;
      name: string | null;
      attachment: string;
      extention: string;
      documentLink: string;
    };
  }>;
  ProductDetail: Array<{
    ProductId: string;
    Product: string;
    Quantity: string;
    CostPerHectare: string;
    UOM: string;
  }>;
  [key: string]: any;
}

interface ActivitiesDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FormData) => void;
  formData: any;
  handleDelete?: () => void;
}

const ActivityDialog: React.FC<ActivitiesDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  formData,
  handleDelete,
}) => {
  const { activityCategories, activityStatuses, seasonStages, mappedfields, crops, products, selectedOrganization } = useConfig();

  const [isLoading, setIsLoading] = useState(false);
  const [dynamicFields, setDynamicFields] = useState<any[]>([]);
  const generalActivityCategoryId = activityCategories[0].activityCategoryId;
  const [activityNotes, setActivityNotes] = useState<FormData["ActivityNotes"]>([]);
  const [activityProducts, setActivityProducts] = useState<FormData["ProductDetail"]>([]);
  const [openDialogId, setOpenDialogId] = useState<string | null>(null);
  const [totalCost, setTotalCost] = useState<number>(0);

  const [cultivarOptions, setCultivarOptions] = useState([]);
  
  const [editingNote, setEditingNote] = useState<any>(null);
  const [editingNoteIndex, setEditingNoteIndex] = useState<number | null>(null);

  const [farms, setFarms] = useState<any[]>([]);
  const [linkedFieldsMap, setLinkedFieldsMap] = useState<{ [key: string]: string }>({});
  const [fieldsWithFarms, setFieldsWithFarms] = useState<any[]>([]);
  
const baseSchema = z.object({
  activityCategoryId: z.number().nonnegative("Activity Category is required"),
  seasonStageId: z.number().nonnegative("Season stage is required"),
  activityStatusId: z.number().nonnegative("Status is required"),
  description: z.string().max(200, "Description must be at most 200 characters long").optional(),
  name: z.string().min(3, "Title must be at least 3 characters long"),
  fields: z.array(z.number()).optional(),
  startDate: z.string().min(1, "Start date is required"),
  endDate: z.string().min(1, "End date is required"),
  contractWorkCost: z.string().refine((val) => !isNaN(parseFloat(val)) && parseFloat(val) >= 0, {
    message: "Contract Work Cost must be zero or greater",
  }),
}).passthrough();


const dynamicSchema = dynamicFields.reduce((acc, field) => {
  if (field.type === "multiSelect" || field.id === 'cultivar') {
    acc[field.id] = field.required
      ? z.array(z.string()).min(1, `At least one ${field.label} must be selected`)
      : z.array(z.string()).optional();
  } else {
    acc[field.id] = field.required
      ? z.string().min(1, `${field.label} is required`)
      : z.string().optional();
  }
  return acc;
}, {} as Record<string, z.ZodTypeAny>);

const extendedSchema = baseSchema.extend(dynamicSchema);

const combinedSchema = extendedSchema.superRefine((data, ctx) => {

  if (!data.startDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Start date is required",
      path: ["startDate"],
    });
  }

  if (!data.endDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "End date is required",
      path: ["endDate"],
    });
  }

  if (data.startDate && data.endDate) {
    if (new Date(data.startDate) > new Date(data.endDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Start date must be earlier than or equal to the end date",
        path: ["startDate"],
      });
    }
  }

  const isFieldOptionalCategory = 
    data.activityCategoryId === generalActivityCategoryId || data.activityCategoryId === 14; 

  if (!isFieldOptionalCategory && (!data.fields || data.fields.length === 0)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Field(s) are required for the selected activity category",
      path: ["fields"],
    });
  }

  if (!data.seasonStageId) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: isApplicationCategory ? "Application timing is required" : "Season stage is required",
      path: ["seasonStageId"],
    });
  }

  dynamicFields.forEach(field => {
    if (field.id === 'cultivar') {
      if (!Array.isArray(data[field.id]) || data[field.id].length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "At least one cultivar must be selected",
          path: [field.id],
        });
      }
    } else if (field.required && !data[field.id]) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `${field.label} is required`,
        path: [field.id],
      });
    }
  
    if (field.conditionalRequired) {
      const { field: conditionField, value: conditionValue } = field.conditionalRequired;
      if (data[conditionField] === conditionValue && !data[field.id]) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `${field.label} is required`,
          path: [field.id],
        });
      }
    }
  });
});

 const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(combinedSchema),
    defaultValues: {
      activityCategoryId: activityCategories[0]?.activityCategoryId,
      seasonStageId: seasonStages[0]?.seasonStageId,
      activityStatusId: activityStatuses[0]?.statusId,
      description: "",
      startDate: "",
      endDate: "",
      cost: "0",
      contractWorkCost: "0",
    },
  });

  const fetchFarmsAndFields = useCallback(async () => {
    try {
      setIsLoading(true);
      const fetchedFarms = await getOrganizationFarms(selectedOrganization?.organizationId ?? 0);
      setFarms(fetchedFarms);

      const fieldToFarmMap: { [key: string]: string } = {};

      await Promise.all(
        fetchedFarms.map(async (farm) => {
          const linkedFields = await getLinkedFields(farm.farmIdentifier);
          linkedFields.forEach((field) => {
            fieldToFarmMap[field.fieldId] = farm.farm;
          });
        })
      );

      setLinkedFieldsMap(fieldToFarmMap);

      const enrichedFields = mappedfields
        .map((field) => ({
          ...field,
          farmName: fieldToFarmMap[field.fieldId] || 'Unknown Farm'
        }))
        .sort((a, b) => {
          const farmComparison = a.farmName.localeCompare(b.farmName);
          if (farmComparison === 0) {
            return a.name.localeCompare(b.name);
          }
          return farmComparison;
        });

      setFieldsWithFarms(enrichedFields);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching farms and fields:", error);
      setIsLoading(false);
    }
  }, [selectedOrganization?.organizationId, mappedfields]);

  useEffect(() => {
    if (isOpen) {
      fetchFarmsAndFields();
    }
  }, [isOpen, fetchFarmsAndFields]);

  const sortedFieldOptions = useMemo(() => {
    return fieldsWithFarms.map((field) => ({
      value: field.fieldId,
      label: `${field.farmName} - ${field.name} (${field.area} ha)`,
      id: field.fieldId,
    }));
  }, [fieldsWithFarms]);

  const activityCategoryId = watch("activityCategoryId");
  
  const isApplicationCategory = useMemo(() => {
    const selectedCategory = activityCategories.find(
      (category) => category.activityCategoryId === activityCategoryId
    );
    return selectedCategory?.name === "Application";
  }, [activityCategoryId, activityCategories]);

  //const cropType = watch('cropType');

  const cropTypeOptions = crops.map((crop) => ({
    id: `cropType_${crop.CropId}`,
    label: crop.Name,
    value: crop.CropId,
  }));

  const setNestedFieldValues = useCallback((fields, properties) => {
    fields.forEach((field) => {
      const existingValue = properties[field.id] || formData[field.id];
      setValue(field.id, existingValue ?? '');

      if (field.options && field.options.length > 0) {
        const selectedOption = field.options.find(option => option.id === existingValue);
        if (selectedOption && selectedOption.children) {
          setNestedFieldValues(selectedOption.children, properties);
        }
      }
    });
  }, [setValue, formData]);

  useEffect(() => {
    if (!isOpen) return;

    try {
      if (formData) {
        const parsedProperties = formData.properties ? JSON.parse(formData.properties) : {};
        const formattedStartDate = formData.startDate ? formData.startDate.split('T')[0] : "";
        const formattedEndDate = formData.endDate ? formData.endDate.split('T')[0] : "";

        const productDetail = parsedProperties.ProductDetail || [];
        setActivityNotes(formData.notes);
        setActivityProducts(productDetail);

        reset({
          ...formData,
          ...parsedProperties,
          activityStatusId: formData.statusId ?? activityStatuses[0]?.statusId ?? "",
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          contractWorkCost: String(formData.contractWorkCost) ?? "0",
          ProductDetail: productDetail,
        });

        const selectedCategory = activityCategories.find((type) => type.activityCategoryId === formData.activityCategoryId);
        if (selectedCategory) {
          const parsedFields = Array.isArray(selectedCategory?.properties?.fields) 
            ? selectedCategory.properties.fields.map(field => ({
                ...field,
                activityCategoryId: selectedCategory.activityCategoryId
              }))
            : [];
          setDynamicFields(parsedFields);
          setNestedFieldValues(parsedFields, parsedProperties);
        }

        clearErrors('fields');
      }
    } catch (error) {
      console.error("Error in activity dialog setup:", error);
    }
  }, [isOpen, formData, reset, activityCategories, activityStatuses, setNestedFieldValues, clearErrors]);

  useEffect(() => {
    const selectedCategory = activityCategories.find((type) => type.activityCategoryId === activityCategoryId);
    if (selectedCategory) {
      const categoryProperties = selectedCategory.properties;
      const parsedFields = categoryProperties.fields || [];
      
      setDynamicFields(parsedFields);
    
      parsedFields.forEach((field) => {
        if (field.id === 'cropType') {
          field.options = cropTypeOptions;
        }

        const existingValue = watch(field.id);

        if (existingValue === undefined && field.required && field.options && field.options.length > 0) {
          const defaultOptionValue = field.options[0].id;
          setValue(field.id, field.type === 'multiSelect' ? [defaultOptionValue] : defaultOptionValue);
        }
      });

      const matchingSeasonStage = seasonStages.find((stage) => stage.name === selectedCategory.name);
      if (matchingSeasonStage) {
        setValue("seasonStageId", matchingSeasonStage.seasonStageId);
      }
    }
  }, [activityCategoryId, activityCategories, seasonStages, setValue, cropTypeOptions]);

  useEffect(() => {
    const cropType = watch('cropType');
  
    if (cropType) {
      const cropId = cropType.split('_')[1];
  
      getCultivars(parseInt(cropId, 10))
        .then((response) => {
          const newCultivarOptions = response.Variants.map((variant) => ({
            id: variant,  
            label: variant 
          }));
  
          setCultivarOptions(newCultivarOptions);
          
          const existingCultivar = formData?.cultivar || watch('cultivar') || [];
          setValue('cultivar', existingCultivar);
        })
        .catch((error) => {
          console.error('Error fetching cultivars:', error);
        });
    } else {
      setCultivarOptions([]);
      setValue('cultivar', []);
    }
  }, [watch, formData, setValue, watch('cropType')]);

  useEffect(() => {
    if (formData?.activityCategoryId) {
      setDynamicFields(Array.isArray(formData.activityCategoryId) ? formData.activityCategoryId : []);
    }
  }, [formData]);
   

  const handleFieldChange = (fieldId: string, selectedValue: string) => {
    setDynamicFields(prevFields => {
      const updatedFields = [...prevFields];
      const fieldIndex = updatedFields.findIndex(field => field.id === fieldId);

      if (fieldIndex !== -1) {
        const field = updatedFields[fieldIndex];
        const selectedOption = field.options?.find(option => option.id === selectedValue);

        if (selectedOption && selectedOption.children) {
          updatedFields.splice(fieldIndex + 1, updatedFields.length - fieldIndex - 1);
          updatedFields.push(...selectedOption.children);
        }
      }

      return updatedFields;
    });
  };

  const handleOpenDialog = (dialogId: string) => {
    setOpenDialogId(dialogId);
  };
  
  const handleCloseDialog = () => {
    setOpenDialogId(null);
    setEditingNote(null);
    setEditingNoteIndex(null);
  };

  const handleCloseMainDialog = () => {
    onClose();
    reset();
    setActivityNotes([]);
    setActivityProducts([]);
    setDynamicFields([]);
  };

  const handleRequestDeleteNote = (index: number) => {
    const updatedNotes = activityNotes.filter((_, i) => i !== index);
    setActivityNotes(updatedNotes);
    setValue("notes", updatedNotes);
  };

  const handleRequestDeleteProduct = (index: number) => {
    const updatedProducts = activityProducts.filter((_, i) => i !== index);
    setActivityProducts(updatedProducts);
    setValue("ProductDetail", updatedProducts);
  };

  const handleViewNote = (note: any, index: number) => {
    setEditingNote(note);
    setEditingNoteIndex(index);
    handleOpenDialog("ActivityNotesDialog");
  };
  
  //SUBMIT FUNCTIONS
  const handleNoteSubmit = async (note: {
    Name: string | null;
    Title: string;
    Description: string;
    Attachment: string | null;
    Extention: string | null;
  }) => {
    setActivityNotes((prevNotes) => {
      const newNote = {
        title: note.Title,
        description: note.Description,
        ...(note.Attachment && note.Extention ? {
          Document: {
            Name: note.Name || "Untitled",
            attachment: note.Attachment,
            extention: note.Extention
          }
        } : {})
      };
      
      if (editingNoteIndex !== null) {
        const updatedNotes = [...prevNotes];
        updatedNotes[editingNoteIndex] = newNote;
        setValue("notes", updatedNotes);
        return updatedNotes;
      } else {
        const updatedNotes = [...prevNotes, newNote];
        setValue("notes", updatedNotes);
        return updatedNotes;
      }
    });
    
    setEditingNote(null);
    setEditingNoteIndex(null);
  };
  
  const [editingProductIndex, setEditingProductIndex] = useState(null);

  const handleEditProduct = (index) => {
    setEditingProductIndex(index);
    const productToEdit = activityProducts[index];
    handleOpenDialog("ActivityProductsDialog");
    setValue("Product", productToEdit.ProductId);
    setValue("Quantity", productToEdit.Quantity);
    setValue("CostPerHectare", productToEdit.CostPerHectare);
    setValue("UOM", productToEdit.UOM);
  };

  const handleProductSubmit = (product) => {
    if (editingProductIndex !== null) {
      setActivityProducts((prevProducts) => {
        const updatedProducts = [...prevProducts];
        updatedProducts[editingProductIndex] = {
          ProductId: product.productId,
          Product: product.product,
          Quantity: product.quantity.toString(),
          CostPerHectare: product.costPerHectare.toString(),
          UOM: product.uom,
        };
        setValue("ProductDetail", updatedProducts);
        return updatedProducts;
      });
      setEditingProductIndex(null);

    } else {
      const newProduct = {
        ProductId: product.productId,
        Product: product.product,
        Quantity: product.quantity.toString(),
        CostPerHectare: product.costPerHectare.toString(),
        UOM: product.uom,
      };
      setActivityProducts((prevProducts) => {
        const updatedProducts = [...prevProducts, newProduct];
        setValue("ProductDetail", updatedProducts);
        return updatedProducts;
      });
    }
    handleCloseDialog();
  };

  const watchedFields = useWatch({
    control,
    name: [
      "contractWorkCost",
      "ProductDetail",
      "fields",
      "quantity",
      "cost",
      "quantityType",
      ...dynamicFields.map(field => field.id)
    ]
  });

  const productCostPerHectare = activityProducts.reduce((total, product) => {
    return total + parseFloat(product.CostPerHectare || '0');
  }, 0).toFixed(2);

  const calculateTotalCost = useCallback(() => {
    if (!isOpen) return 0;
  
    const [
      contractWorkCost,
      productDetails,
      selectedFields,
      quantity,
      cost,
      quantityType,
      ...dynamicValues
    ] = watchedFields;
  
    const parsedContractWorkCost = parseFloat(contractWorkCost) || 0;
    const parsedQuantity = parseFloat(quantity) || 0;
    const parsedCost = parseFloat(cost) || 0;
  
    //let dynamicCost = parsedQuantity * parsedCost;
    let dynamicCost = parsedCost;
  
    const totalarea = (selectedFields || []).reduce((total, fieldId) => {
      const field = mappedfields.find((f) => f.fieldId === fieldId);
      return total + (field?.area || 0);
    }, 0);

    if (activityCategoryId === 14) {
      dynamicCost *= parsedQuantity;
    }
  
    if (["hectare_seeds", "hectare_g", "hectare_kg", "hectare_tonne", "hectare_l"].includes(quantityType)) {
      dynamicCost *= totalarea;
    }
  
    // const productCost = (productDetails || []).reduce((total, product) => {
    //   const productCostPerHectare = parseFloat(product.CostPerHectare) || 0;
    //   return total + (productCostPerHectare * totalarea);
    // }, 0);
  
    // const totalCost = dynamicCost + productCost + parsedContractWorkCost;

    if (activityCategoryId === 11 || activityCategoryId === 13 || activityCategoryId === 15 || activityCategoryId === 16) {
      dynamicCost = 0;
    }

    const totalCost = dynamicCost + parsedContractWorkCost;
  
    console.log("Total cost calculation:", {
      dynamicCost,
      contractWorkCost: parsedContractWorkCost,
      totalarea,
      totalCost
    });
  
    return parseFloat(totalCost.toFixed(2));
  }, [isOpen, watchedFields, mappedfields, activityCategoryId]);
  

  useEffect(() => {
    if (isOpen) {
      const newTotalCost = calculateTotalCost();
      setTotalCost(newTotalCost);
      console.log("Total cost updated:", newTotalCost);
    }
  }, [isOpen, calculateTotalCost]);
 

  const handleFormSubmit = (data: FormData) => {
    setIsLoading(true);
    const properties: { [key: string]: any } = {};

    console.log("Raw form data:", data);
    console.log("Dynamic fields:", dynamicFields);

    const processFields = (fields: any[], parentKey = '') => {
      fields.forEach((field) => {
        const fieldKey = parentKey ? `${parentKey}.${field.id}` : field.id;
        let value = data[field.id];
        
        console.log(`Processing field: ${fieldKey}, value:`, value);
        
        if (value !== undefined && value !== null && value !== '') {
          if (field.id === 'cultivar') {
            properties[field.id] = Array.isArray(value) ? value : [value];
          } else {
            properties[field.id] = value;
          }
          
          console.log(`Added to properties: ${field.id}:`, properties[field.id]);
    
          if (field.options && Array.isArray(field.options)) {
            const selectedOption = field.options.find(option => option.id === value);
            console.log(`Selected option for ${fieldKey}:`, selectedOption);
            if (selectedOption && selectedOption.children) {
              console.log(`Processing children of ${fieldKey}`);
              processFields(selectedOption.children, field.id);
            }
          }
        }
      });
    };

    processFields(dynamicFields);

    if (data.ProductDetail && data.ProductDetail.length > 0) {
      properties["ProductDetail"] = data.ProductDetail;
    }

    console.log("Properties before final processing:", properties);

    if (properties.applicationType === 'applicationType_fertilizer') {
      if (data.fertilizer_type) {
        properties.fertilizer_type = data.fertilizer_type;
        if (data.fertilizer_type === 'fertilizer_type_organic' && data.fertilizerType_organic) {
          properties.fertilizerType_organic = data.fertilizerType_organic;
        } else if (data.fertilizer_type === 'fertilizer_type_inorganic' && data.fertilizerType_inorganic) {
          properties.fertilizerType_inorganic = data.fertilizerType_inorganic;
        }
      }
    } else if (properties.applicationType === 'applicationType_soilConditioner' && data.soilConditionerType) {
      properties.soilConditionerType = data.soilConditionerType;
    }

    console.log("Final properties:", properties);

    const filteredActivityNotes = activityNotes
    .filter((note) => !note.DocumentId)
    .map(note => {
      const documentData = note.Document && note.Document.attachment && note.Document.extention
        ? {
            Name: note.Document.Name || "Untitled",
            attachment: note.Document.attachment,
            extention: note.Document.extention
          }
        : null;

      return {
        title: note.title,
        description: note.description,
        ...(documentData ? { Document: documentData } : {})
      };
    });
  
    if (data.applicationType !== 'applicationType_chemical') {
      setActivityProducts([]);
    }
  
    const finalData: FormData = {
      activityId: data.activityId,
      name: data.name,
      description: data.description,
      startDate: data.startDate,
      endDate: data.endDate,
      fields: data.fields,
      cost: totalCost.toString(),
      contractWorkCost: data.contractWorkCost,
      Properties: JSON.stringify(properties),
      activityCategoryId: data.activityCategoryId,
      seasonStageId: data.seasonStageId,
      partyId: data.partyId,
      activityStatusId: data.activityStatusId,
      notes: filteredActivityNotes.length > 0 ? filteredActivityNotes : undefined,
      ProductDetail: data.ProductDetail,
    };
  
    console.log('Final Data to Submit:', finalData);
    onSubmit(finalData);
    setIsLoading(false);
  };

  const formContent = (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <DialogContent dividers sx={{ pt: 1, pb: 1, maxHeight: '70vh', overflowY: 'auto' }}>
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={6}>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextBox
                  label="Activity Name"
                  value={value}
                  onChange={onChange}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
  
          <Grid item xs={6}>
            <Controller
              name="activityCategoryId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextBox
                  select
                  label="Activity Category"
                  value={value}
                  onChange={onChange}
                  error={!!errors.activityCategoryId}
                  helperText={errors.activityCategoryId?.message}
                  fullWidth
                  disabled={!!formData}
                >
                  {activityCategories?.map((type) => (
                    <MenuItem key={type.activityCategoryId} value={type.activityCategoryId}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextBox>
              )}
            />
          </Grid>
          
          <Grid item xs={6}>
            <Controller
              name="startDate"
              control={control}
              rules={{ required: "Start date is required" }}
              render={({ field: { onChange, value, onBlur } }) => (
                <DateSelector
                  label="Start Date"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.startDate}
                  helperText={errors.startDate?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="endDate"
              control={control}
              rules={{ required: "End date is required" }}
              render={({ field: { onChange, value, onBlur } }) => (
                <DateSelector
                  label="End Date"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.endDate}
                  helperText={errors.endDate?.message}
                  minDate={watch("startDate")} 
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="fields"
              control={control}
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  label="Field(s)"
                  value={value || []}
                  onChange={onChange}
                  options={sortedFieldOptions}
                  error={!!errors.fields}
                  helperText={errors.fields?.message}
                  fieldId="fields"
                  sx={{ mb: 1 }}
                />
              )}
            />
          </Grid>
  
          <Grid item xs={6}>
            <Controller
              name="activityStatusId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextBox
                  select
                  label="Activity Status"
                  value={value}
                  onChange={onChange}
                  error={!!errors.activityStatusId}
                  helperText={errors.activityStatusId?.message}
                  fullWidth
                >
                  {activityStatuses?.map((type) => (
                    <MenuItem key={type.statusId} value={type.statusId}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextBox>
              )}
            />
          </Grid>
  
          {/* <Grid item xs={6}>
            <Controller
              name="seasonStageId"
              control={control}
              render={({ field: { onChange, value } }) => {
                const options = isApplicationCategory ? applicationTimings : seasonStages;
                const currentValue = options.some(option => option.seasonStageId === value) ? value : options[0].seasonStageId;

                return (
                  <TextBox
                    select
                    label={isApplicationCategory ? "Application Timing" : "Season Stages"}
                    value={currentValue}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    error={!!errors.seasonStageId}
                    helperText={errors.seasonStageId?.message}
                    fullWidth
                  >
                    {options.map((stage) => (
                      <MenuItem key={stage.seasonStageId} value={stage.seasonStageId}>
                        {stage.name}
                      </MenuItem>
                    ))}
                  </TextBox>
                );
              }}
            />
          </Grid> */}

          {!isApplicationCategory && (
            <Grid item xs={6}>
              <Controller
                name="seasonStageId"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const options = seasonStages;
                  const currentValue = options.some(option => option.seasonStageId === value) ? value : options[0].seasonStageId;

                  return (
                    <TextBox
                      select
                      label="Season Stages"
                      value={currentValue}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      error={!!errors.seasonStageId}
                      helperText={errors.seasonStageId?.message}
                      fullWidth
                    >
                      {options.map((stage) => (
                        <MenuItem key={stage.seasonStageId} value={stage.seasonStageId}>
                          {stage.name}
                        </MenuItem>
                      ))}
                    </TextBox>
                  );
                }}
              />
            </Grid>
          )}

          <DynamicFieldRenderer
            fields={dynamicFields}
            control={control}
            errors={errors}
            handleFieldChange={handleFieldChange}
            handleOpenDialog={handleOpenDialog}
            watch={watch}
            setValue={setValue}
            cultivarOptions={cultivarOptions}
            productCostPerHectare={productCostPerHectare} 
            isApplicationCategory={isApplicationCategory} 
          />

          {/* {activityProducts.length > 0 && watch('applicationType') === "applicationType_chemical" && (
            <Grid item xs={12} sx={{ mt: 2 }}>
              {activityProducts.map((product, index) => (
                <Chip
                  key={index}
                  label={`${product.Product} (${product.Quantity} ${product.UOM})`}
                  onDelete={() => {
                    handleRequestDeleteProduct(index);
                  }}
                  deleteIcon={<CancelIcon />}
                  sx={{
                    mr: 1,
                    mb: 1,
                    backgroundColor: theme.palette.secondary.main,
                  }}
                />
              ))}
            </Grid>
          )} */}

          {activityProducts.length > 0 && watch('applicationType') === "applicationType_chemical" && (
            <Grid item xs={12}>
              <ProductTable 
                products={activityProducts}
                handleRequestDeleteProduct={handleRequestDeleteProduct}
                handleEditProduct={handleEditProduct}
              />
            </Grid>
          )}
  
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextBox
                  label="Description"
                  value={value}
                  onChange={onChange}
                  multiline
                  rows={4}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
  
          <Grid item xs={6}>
            <Controller
              name="contractWorkCost"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextBox
                  label="Contract Work Cost"
                  value={value}
                  onChange={onChange}
                  type="currency"
                  error={!!errors.contractWorkCost}
                  helperText={errors.contractWorkCost?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
  
          <Grid item xs={6}>
            <Controller
              name="cost"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextBox
                  label="Total Cost"
                  value={totalCost}
                  onChange={onChange}
                  type="currency"
                  error={!!errors.cost}
                  helperText={errors.cost?.message}
                  fullWidth
                  disabled
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<NoteAddIcon />}
              onClick={() => handleOpenDialog("ActivityNotesDialog")}
              fullWidth
              sx={{ mt: 2 }}
            >
              Add Note
            </Button>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            {activityNotes.map((note: any, index) => (
              <Chip
                key={index}
                label={note.title || note.Title || "Untitled Note"}
                onDelete={() => handleRequestDeleteNote(index)}
                onClick={() => handleViewNote(note, index)}
                deleteIcon={<CancelIcon />}
                sx={{
                  mr: 1,
                  mb: 1,
                  backgroundColor: theme.palette.secondary.main,
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.9
                  }
                }}
              />
            ))}
          </Grid>
  
        </Grid>
      </DialogContent>
  
      <DialogActions>
        {formData && (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Delete />}
            onClick={handleDelete}
          >
            Delete
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<SaveIcon />}
          disabled={isLoading}
        >
          {formData ? "Update" : "Save"}
          {isLoading && (
            <CircularProgress color="inherit" size={"15px"} sx={{ ml: 2 }} />
          )}
        </Button>
      </DialogActions>
    </form>
  );
  
  return (
<>
  <DynamicFormDialog
    isOpen={isOpen}
    onClose={handleCloseMainDialog}
    onSubmit={handleSubmit(handleFormSubmit)}
    title={formData ? "Update Activity" : "Add Activity"}
    formContent={formContent}
  />

  <ActivityNotesDialog
    isOpen={openDialogId === "ActivityNotesDialog"}
    onClose={handleCloseDialog}
    onSubmit={handleNoteSubmit}
    editingNote={editingNote}
    editingIndex={editingNoteIndex}
  />

  <ActivityProductsDialog
    isOpen={openDialogId === "ActivityProductsDialog"}
    onClose={() => {
      handleCloseDialog();
      setEditingProductIndex(null);
    }}
    products={products}
    onSubmit={handleProductSubmit}
    editingProduct={editingProductIndex !== null ? activityProducts[editingProductIndex] : null}
  />
</>
  );
};

export default ActivityDialog;