import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  TextField,
  Button,
  Typography,
  Container,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { addUser } from "../../../services/api-ffm-service";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../auth-config";

const passwordSchema = z
  .string()
  .min(8, "Password must be at least 8 characters long")
  .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
  .regex(/[a-z]/, "Password must contain at least one lowercase letter")
  .regex(/[0-9]/, "Password must contain at least one number")
  .regex(
    /[@$!%*?&#_\-+={}[\]:;"'<>,./\\~`^|()]/,
    "Password must contain at least one special character"
  );

const schema = z
  .object({
    email: z.string().email("Invalid email address"),
    name: z.string().min(1, "Name is required"),
    surname: z.string().min(1, "Surname is required"),
    password: passwordSchema,
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "Passwords do not match",
  });

type FormData = z.infer<typeof schema>;

interface UserDetailsProps {
  email: string;
}

const UserDetails: React.FC<UserDetailsProps> = ({ email }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: email,
      name: "",
      surname: "",
      password: "",
      confirmPassword: "",
    },
  });

  const { instance } = useMsal();
  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    try {
      await addUser(data.email, data.name, data.surname, data.password);

      await instance.logoutRedirect({
        onRedirectNavigate: (url) => {
          return false;
        },
      });
      await instance.loginRedirect({
        ...loginRequest,
        prompt: "login",
      });
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        User Details
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Email Address"
              variant="outlined"
              margin="dense"
              error={!!errors.email}
              helperText={errors.email?.message}
              InputProps={{ readOnly: true }}
            />
          )}
        />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Name"
              variant="outlined"
              margin="dense"
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          )}
        />
        <Controller
          name="surname"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Surname"
              variant="outlined"
              margin="dense"
              error={!!errors.surname}
              helperText={errors.surname?.message}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Password"
              variant="outlined"
              margin="dense"
              type={showPassword ? "text" : "password"}
              error={!!errors.password}
              helperText={errors.password?.message}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Confirm Password"
              variant="outlined"
              margin="dense"
              type={showPassword ? "text" : "password"}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
            />
          )}
        />
        <FormControlLabel
          sx={{
            display: "flex",
            alignItems: "left",
            justifyContent: "left",
            alignContent: "left",
            pl: "5px",
          }}
          control={
            <Checkbox
              checked={showPassword}
              onChange={(e) => setShowPassword(e.target.checked)}
              color="primary"
            />
          }
          label="Show Password"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 2 }}
          disabled={!isValid || isLoading}
        >
          Submit
          {isLoading && (
            <CircularProgress color="inherit" size={"15px"} sx={{ ml: 2 }} />
          )}
        </Button>
        {error && (
          <Typography
            variant="caption"
            display="block"
            sx={{ marginTop: 1 }}
            color={"red"}
          >
            {error}, please retry signing up.
          </Typography>
        )}
      </form>
    </Container>
  );
};

export default UserDetails;
