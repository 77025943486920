import { useState, useEffect, useRef, useCallback } from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  IconButton,
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  TextField,
  AlertColor,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Delete as DeleteIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { fetchData } from "../../hooks/useFetchData";
import {
  getOrganizationFarms,
  deleteFarm,
  updateFarm,
  createFarm,
} from "../../services/api-ffm-service";
import { getLinkedFields, deactivateFarmFields } from "../../services/api-gs-service";
import { Farm } from "../../models/farm.interface";
import GenericConfirmDialog from "../organisms/genericConfirmDialog";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { styled } from "@mui/material/styles";
import CustomSnackbar from "../atom/CustomSnackbar";
import { handleApiResponse } from "../../utils/Utilities";
import { useConfig } from "../../context/ConfigContextTest";

const validationSchema = yup.object({
  farmName: yup.string().required("Farm name is required"),
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function FarmFieldManagement() {
  const [farms, setFarms] = useState<Farm[]>([]);
  const [linkedFields, setLinkedFields] = useState<{ [key: number]: any[] }>({});
  const [expandedFarms, setExpandedFarms] = useState<{ [key: number]: boolean }>({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedFarmId, setSelectedFarmId] = useState<number | null>(null);
  const [selectedFarmIdentifier, setSelectedFarmIdentifier] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentFarm, setCurrentFarm] = useState<Farm | null>(null);
  const { selectedOrganization } = useConfig();
  const snackbarRef = useRef<{ handleOpen: (title: string, msg: string | null, sev: AlertColor) => void }>(null);

  const navigate = useNavigate();

  // useFetchData(getOrganizationFarms, setFarms, undefined, [
  //   selectedOrganization?.organizationId ?? 0,
    
  // ]);

  // console.log(selectedOrganization?.organizationId)
  // console.log(farms)

  const fetchFarms = useCallback(async () => {
    console.log("Fetching farms for organizationId:", selectedOrganization?.organizationId);
    const fetchedFarms = await getOrganizationFarms(selectedOrganization?.organizationId ?? 0);
    console.log("Fetched farms:", fetchedFarms);
    setFarms(fetchedFarms);
  }, [selectedOrganization?.organizationId]);

  useEffect(() => {
    fetchFarms();
  }, [fetchFarms]);

  // useEffect(() => {
  //   console.log("Current selectedOrganization:", selectedOrganization);
  //   console.log("Current farms:", farms);
  // }, [selectedOrganization, farms]);
  

  const collapseFarmsButCurrent = (farmId: number) => {
    farms.forEach(farm => {
      if(farm.farmId !== farmId) {
        setExpandedFarms(() => ({
          [farm.farmId]: false,     
        }));   
      }
    }); 
  }   

  const toggleFarm = (farmId: number, farmIdentifier: string) => {

    if (!expandedFarms[farmId]) {
      collapseFarmsButCurrent(farmId);
    }
      
    setExpandedFarms((prevState) => ({
      ...prevState,
      [farmId]: !prevState[farmId],
    }));

    if (!expandedFarms[farmId]) {
      fetchData(
        getLinkedFields,
        async (data) => {
          const transformedData = await Promise.all(
            data.map(async (fieldData) => {
              console.log(fieldData);
              const area = fieldData.area;
              const isIrrigated = fieldData.irrDry === "Irrigated";
             
              const fieldIconUrl = `${process.env.REACT_APP_FFM_BASE_URL}/api/fieldicon?farmFieldLinkingIdentifier=${fieldData.farmFieldLinkIdentifier}`;

              return {
                ...fieldData,
                area,
                isIrrigated,
                fieldIconUrl,
              };
            })
          );
          setLinkedFields((prevState) => ({
            ...prevState,
            [farmId]: transformedData,
          }));
        },
        undefined,
        [farmIdentifier]
      );
    }
  };

  const handleNavigation = (fieldData, page) => {
    navigate(page, { state: { fieldData } });
  };

  const openDeleteConfirm = (farm, event) => {
    const farmId = farm.farmId;
    const farmIdentifier = farm.farmIdentifier;
    event.stopPropagation();
    setSelectedFarmId(farmId);
    setSelectedFarmIdentifier(farmIdentifier);
    setConfirmOpen(true);
  };

  const handleConfirm = async () => {
    let response;
    if (selectedFarmId && selectedFarmIdentifier) {
      try {
        response = await deleteFarm(selectedFarmId);
        if(response.status === 200){
          setFarms(farms.filter((farm) => farm.farmId !== selectedFarmId));
          await deactivateFarmFields(selectedFarmIdentifier);
        }
      } catch (error) {
        console.error("Failed to delete farm:", error);
      }
    }
    setConfirmOpen(false);
    handleApiResponse(response, showSnackbar, {title: "Farm successfully deleted"});
  };

  const handleFieldEdit = (farm) => {
    setCurrentFarm(farm);
    setDialogOpen(true);
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (currentFarm) {
      reset({ farmName: currentFarm.farm });
    } else {
      reset({ farmName: "" });
    }
  }, [currentFarm, reset]);

  const onSubmit = async (formData: any) => {
    let response, title;

    try {
      if (currentFarm) {
        const updateData = {
          farmId: currentFarm.farmId,
          name: formData.farmName,
          partyId: selectedOrganization?.partyId,
        };
        response = await updateFarm(updateData);
        title = "Farm successfully updated";
      } else {
        const createData = {
          name: formData.farmName,
          partyId: selectedOrganization?.partyId,
        };
        response = await createFarm(createData);
        title = "Farm successfully created";
      }
    } catch (error) {
      console.error("Error submitting farm:", error);
    }

    fetchData(getOrganizationFarms, setFarms, undefined, [
      selectedOrganization?.organizationId ?? 0,
    ]);
    handleCloseForm();
    handleApiResponse(response, showSnackbar, {title: title});
  };

  const handleCloseForm = () => {
    setDialogOpen(false);
    setCurrentFarm(null);
  };

  const showSnackbar = (title, message, severity) => {
    if (snackbarRef.current) {
      snackbarRef.current.handleOpen(title, message, severity);
    }
  };
  const cardParent = document.getElementById('farm-field-manage-wrapper');
  
  return (
    <div
      style={{
        position: "absolute",
        top: 104,
        padding: 10,
        height: "calc(100vh - 134px)",
        width: cardParent?.offsetWidth,
        overflowY: "scroll"
      }}
    >
      <List>
        <Button
          variant="contained"
          fullWidth
          startIcon={<AddIcon />}
          onClick={() => {
            setCurrentFarm(null);
            setDialogOpen(true);
          }}
        >
          Add Farm
        </Button>
        {selectedOrganization &&
          Array.isArray(farms) &&
          farms.map((farm) => (
            <div key={farm.farmId}>
              <ListItemButton
                onClick={() => toggleFarm(farm.farmId, farm.farmIdentifier)}
              >
                <ListItemText 
                  primary={farm.farm}
                  primaryTypographyProps={{
                    style: {
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal',
                      fontSize: '0.875rem' 
                    }
                  }}
                />
                {expandedFarms[farm.farmId] ? <ExpandLess /> : <ExpandMore />}
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleFieldEdit(farm);
                  }}
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(event) => openDeleteConfirm(farm, event)}
                  color="primary"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemButton>
              <Collapse
                in={expandedFarms[farm.farmId]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {linkedFields[farm.farmId]?.map((field, index) => (
                    <ListItemButton 
                      key={index} 
                      style={{ paddingTop: 4, paddingBottom: 4 }}
                      onClick={() => handleNavigation(field, "/fields")}
                      
                    >
                      <div style={{ width: 40, height: 40, marginRight: 8, borderRadius: 4, backgroundColor: "#3c4f1e" }}> 
                      <img 
                        src={field.fieldIconUrl} 
                        style={{ width: 40, height: 40, borderRadius: 4 }}
                        alt=""
                      />
                      </div>  
                      <ListItemText 
                        primary={field.fieldName} 
                        secondary={field.area + " ha" + (field.isIrrigated ? " irrigated" : "")} 
                        style={{ marginTop: 2, marginBottom: 2 }} />
                      
                    </ListItemButton>
                  ))}
                  <Divider />
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => navigate('/fields', { 
                      state: { 
                        isAddingField: true,
                        farmData: {
                          farmId: farm.farmId,
                          farmIdentifier: farm.farmIdentifier
                        }
                      } 
                    })}
                    fullWidth
                  >
                    Add Field
                  </Button>
                </List>
              </Collapse>
            </div>
          ))}
      </List>
      <GenericConfirmDialog
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={handleConfirm}
        title="Confirm Deletion"
        content="Are you sure you want to delete this farm?"
      />

      {/* Add/Edit Farm Dialog */}
      <StyledDialog
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
        aria-labelledby="add-edit-farm-dialog"
      >
        <DialogTitle id="add-edit-farm-dialog">
          {currentFarm ? "Edit Farm" : "Add New Farm"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setDialogOpen(false)}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers>
            <Grid container spacing={2} sx={{ padding: 2 }}>
              <Grid item xs={12}>
                <Controller
                  name="farmName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Farm Name"
                      variant="outlined"
                      fullWidth
                      error={!!errors.farmName}
                      helperText={errors.farmName ? errors.farmName.message : ""}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </StyledDialog>
      <CustomSnackbar ref={snackbarRef} />
    </div>
  );
}