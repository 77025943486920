export const privacyPolicyHtml = `<!DOCTYPE html> 
<html lang="en" xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <meta charset="utf-8">
        <title>Privacy Policy</title>
    </head>
    <body>
        <main id="content" >
            <div id="page-header">
                <p><strong>Privacy Policy</strong></p>
            </div>
            <div id="page-content">
                <div>
                    <p>
                        <strong>Agnify - FarmersFriend (FF) Data Privacy Policy</strong>
                    </p>
                    <p>FF Data services, including (without limitation) our websites, mobile application and other interactive properties through which the services are delivered (collectively, the “Service”) are owned, operated and distributed by FF Data (referred to in this Privacy Policy as “FF Data ” or “we” and through similar words such as “us,” “our,” etc.).</p>
                    <p>This Privacy Policy outlines the personal information that FF Data may collect, how FF Data uses and safeguards that information, and with whom we may share it.</p>
                    <blockquote>
                        <p>
                            <strong>Optional Language:</strong>
                        </p>
                        <p>THIS SERVICE IS OFFERED FROM SOUTH AFRICA (“ZA”), IN PARTICULAR, IT IS NOT INTEDED FOR USE IN THE EUROPEAN UNION (“EU”). For individuals located outside of SA, please note that FF Data is a SA based company. If you use the Service, all information, including personal information, will be transferred to FF Data in SA and used as described in this Policy.</p>
                    </blockquote>
                    <p>FF Data encourages our customers, visitors, business associates, and other interested parties to read this Privacy Policy, which applies to all users. By using our Service or submitting personal information to FF Data by any other means, you acknowledge that you understand and agree to be bound by this Privacy Policy, and agree that FF Data may collect, process, transfer, use, and disclose your personal information as described in this Policy.</p>
                    <p>Further, by accessing any part of the Service, you are agreeing to THE TERMS AND CONDITIONS OF OUR TERMS OF SERVICE (the “Terms of Service”).</p>
                    <blockquote>
                        <p>
                            <strong>IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY Policy OR OUR TERMS OF SERVICE, PLEASE DO NOT USE ANY OF THE SERVICES. Or click on the Following link to our online POPIA request Form</strong><a href="https://inforegulator.org.za/wp-content/uploads/2020/07/FORM-2-REQUEST-FOR-CORRECTION-OR-DELETION-OF-PERSONAL-INFORMATION-OR.pdf"><strong> here</strong></a>
                        </p>
                    </blockquote>
                    <p>
                        <strong>What personal information do we collect about you?</strong>
                    </p>
                    <p>Personal information (also commonly known as personally identifiable information (PII) or (personal data) is information that can be used to identify you, or any other individual to whom the information may relate.</p>
                    <p>The personal information that we collect directly from those registering for the Service, includes the following categories:</p>
                    <ul>
                        <li>
                            <p>Name and contact information (e.g. address; phone number; email, fax);</p>
                        </li>
                        <li>
                            <p>Billing Information (e.g. credit card, bank account, billing contact information)</p>
                        </li>
                        <li>
                            <p>Order Information (e.g. current order/purchase information, purchase history, shipping details).</p>
                        </li>
                        <li>
                            <p>Company information</p>
                        </li>
                        <li>
                            <p>Geographic or location information</p>
                        </li>
                        <li>
                            <p>Other information that may be exchanged in the course of engaging with the Service. You will be aware of any subsequently collected information because it will come directly from you.</p>
                        </li>
                    </ul>
                    <p>.</p>
                    <p>
                        <strong>Collection of User Generated Content</strong>
                    </p>
                    <p>We may invite you to post content on the Service, including your comments and any other information that you would like to be available on the Service, which may become public (“User Generated Content”). If you post User Generated Content, all of the information that you post will be available to authorized personnel of FF Data . You expressly acknowledge and agree that we may access in real-time, record and store archives of any User Generated Content on our servers to make use of them in connection with the Service. If you submit a review, recommendation, endorsement, or other User Generated Content through the Service, or through other websites including Facebook, Instagram, Google, Mailchimp, Whatsapp and other similar channels, we may share that review, recommendation, endorsement or content publicly on the Service.</p>
                    <p>
                        <strong>What are the sources of personal information collected by FF Data ?</strong>
                    </p>
                    <p>When providing personal information to FF Data as described in this Policy, that personal information is collected directly from you, and you will know the precise personal information being collected by us. FF Data does not collect personal information from any other sources, except where it may automatically be collected as described in the section titled “Cookies, Device Data, and How it is used, if the information in that section is considered personal information.</p>
                    <p>
                        <strong>Why does FF Data collect your personal information?</strong>
                    </p>
                    <p>Subject to the terms of this Privacy Policy, FF Data uses the above described categories of personal information in several ways. Unless otherwise stated specifically, the above information may be used for any of the following purposes:</p>
                    <ul>
                        <li>
                            <p>to administer the Service to you;</p>
                        </li>
                        <li>
                            <p>to respond to your requests;</p>
                        </li>
                        <li>
                            <p>to distribute communications relevant to your use of the Service, such as system updates or information about your use of the Service;</p>
                        </li>
                        <li>
                            <p>as may be necessary to support the operation of the Service, such as for billing, account maintenance, and record-keeping purposes;</p>
                        </li>
                        <li>
                            <p>to send to you FF Data solicitations, product announcements, and the like that we feel may be of interest to you. Please note that you may “opt out” of receiving these marketing materials.</p>
                        </li>
                        <li>
                            <p>in other manners after subsequent Policy is provided to you and/or your consent is obtained, if necessary.</p>
                        </li>
                    </ul>
                    <p>
                        <strong>FF Data does not sell, re-sell, or distribute for re-sale your personal information.</strong>
                    </p>
                    <p>
                        <strong>How do we share your Personal Information with third parties?</strong>
                    </p>
                    <p>If you agree to this in writing, we may provide any of the described categories of personal information to FF Data employees, consultants, affiliates or other businesses or persons for the purpose of processing such information on our behalf in order to provide the Service to you. In such circumstances, we require that these parties agree to protect the confidentiality of such information consistent with the terms of this Privacy Policy.</p>
                    <p>We will not share your personal information with other, third-party companies for their commercial or marketing use without your consent or except as part of a specific program or feature which you will specifically be able to opt-out of. In addition, we may release personal information: (i) to the extent we have a good-faith belief that such action is necessary to comply with any applicable law; (ii) to enforce any provision of the Terms of Service , protect ourselves against any liability, defend ourselves against any claims, protect the rights, property and personal safety of any user, or protect the public welfare; (iii) when disclosure is required other persons, consistent with applicable laws (iv) to respond to a court order, subpoena, search warrant, or other legal process, to the extent permitted and as restricted by law; or (v) in the event that we go through a business transition, such as a merger, divestiture, acquisition, liquidation or sale of all or a portion of our assets.</p>
                    <p>
                        <strong>Direct Marketing Communications</strong>
                    </p>
                    <p>We may communicate with you using email, SMS, and other channels (sometimes through automated means) as part of our effort to market our products or services, administer or improve our products or services, or for other reasons stated in this Privacy Policy. You have an opportunity to withdraw consent to receive such direct marketing communications, as permitted by law.</p>
                    <p>
                        If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by submitting a request through the following form <a href="https://inforegulator.org.za/wp-content/uploads/2020/07/FORM-2-REQUEST-FOR-CORRECTION-OR-DELETION-OF-PERSONAL-INFORMATION-OR.pdf" > here</a>, or by using the UNSUBSCRIBE link in any email communication you may have received. Further, you may express you communication preferences by:
                    </p>
                    <ul>
                        <li>
                            <p>Noting your preferences at the time you register your account with the Site or our application.</p>
                        </li>
                        <li>
                            <p>Contacting us using the contact information provided below:</p>
                            <p>Email: unsubscribe@agnify.io</p>
                             
                        </li>
                    </ul>
                    <p>Please note that you may continue to receive non-marketing communications as may be required to maintain your relationship with FF Data.</p>
                    <p>In addition to the communication described here, you may receive third-party marketing communications from providers we have engaged to market or promote our products and services. These third-party providers may be using communications lists they have acquired on their own, and you may have opted-in to those lists through other channels. If you no longer wish to receive emails, SMSs, or other communications from such third parties, you may need to contact that third party directly.</p>
                    <p>
                        <strong>Retention of Data</strong>
                    </p>
                    <p>FF Data will retain your personal information only for as long as is necessary for the purposes set out in this Policy. We will retain and use personal information to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.</p>
                    <p>FF Data will also retain usage data for internal analysis purposes. Usage data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Sites and/or Portals, or we are legally obligated to retain this data for longer periods.</p>
                    <p>FF Data will also retain anonymised data related to geographic areas, locations, crops, and yields, as long as this information contributes to the aggregated agricultural metrics that FF Data maintains.</p>
                    <p>
                        <strong>Cookies, Device Data, and How it is Used</strong>
                    </p>
                    <p>When you use our Service, we may record unique identifiers associated with your device (such as the device ID and IP address), your activity within the Service, and your network location. FF Data uses aggregated information (such as anonymous user usage information, cookies, IP addresses, browser type, clickstream information, etc.) to improve the quality and design of the Service and to create new features, promotions, functionality, and services by storing, tracking, and analyzing user preferences and trends. Specifically, we may automatically collect the following information about your use of Service through cookies, web beacons, and other technologies:</p>
                    <ul>
                        <li>
                            <p>domain name</p>
                        </li>
                        <li>
                            <p>browser type and operating system</p>
                        </li>
                        <li>
                            <p>web pages you view</p>
                        </li>
                        <li>
                            <p>links you click</p>
                        </li>
                        <li>
                            <p>IP address</p>
                        </li>
                        <li>
                            <p>the length of time you visit the Sites, Portals, and/or Services</p>
                        </li>
                        <li>
                            <p>the referring URL or the webpage that led you to the Sites</p>
                        </li>
                    </ul>
                    <p>We may also collect information regarding application-level events, such as crashes, and associate that temporarily with your account to provide customer service. In some circumstances, we may combine this information with personal information collected from you (and third-party service providers may do so on behalf of us).</p>
                    <p>In addition, we may use “cookies,” clear gifs, and log file information that help us determine the type of content and pages to which you link, the length of time you spend at any particular area of the Service, and the portion of the Service you choose to use.</p>
                    <p>A cookie is a small text file that is sent by a website to your computer or mobile device where it is stored by your web browser. A cookie contains limited information, usually a unique identifier and the name of the site. Your browser has options to accept, reject or provide you with Policy when a cookie is sent. Our cookies can only be read by FF Data ; they do not execute any code or virus; and they do not contain any personal information. Cookies allow FF Data to serve you better and more efficiently, and to personalize your experience with the Service. We may use cookies for many purposes, including (without limitation) to save your password so you don’t have to re-enter it each time you visit the Service, and to deliver content (which may include third party advertisements) specific to your interests.</p>
                    <p>We will not use third-party cookies. A third-party cookie is a type of HTTP cookie that is set by a domain other than the one you are currently visiting. These cookies are primarily used for web tracking and advertising purposes. </p>
                    <p>
                        <strong>International Data Transfer</strong>
                    </p>
                    <p>If you are located outside South Africa, please note that we and our servers are located in South Africa. Any information that you provide to us may be transferred to and processed in South Africa or other countries around the world where we do business. Although this may include recipients of information located in countries where there may be a lower level of legal protection for your personal information than in your country, we will protect your information in accordance with requirements applicable to the law in your particular jurisdiction and take steps to only share with third parties that offer similar protection. By using our Service, you unambiguously consent to your information being collected, processed, used, and transferred as disclosed herein.</p>
                    <p>We may use third party service providers to help us deliver certain services, and it may result in the processing of personal information in data centers and locations outside of the [Country of company headquarters]. For example, these service providers may provide us with essential information technology or tools we use to run our business. We may permit these service providers to process our business information and/or your personal information. We do not permit these service providers to process any personal information outside of a contract, and these service providers may collect personal information on our behalf. Our third-party service providers are required to comply fully with this Privacy Policy.</p>
                    <p>
                        <strong>European Union Data Privacy Rights</strong>
                    </p>
                    <p>If you are located in the EEA, the General Data Protection Regulation (“GDPR”) grants you certain rights under the law. In particular, the right to access, correct, and delete the personal information we hold about you. FF Data will retain your personal information for the length of time you engage with our services as described in the retention section of this Policy, until you request deletion of such personal information.</p>
                    <p>In certain circumstances, you have the following data protection rights:</p>
                    <ul>
                        <li>
                        <p>The right to access, update, or delete the personal information we have on you.</p>
                    </li>
                    <li>
                        <p>The right of rectification. You have the right to have your personal information rectified if that information is inaccurate or incomplete.</p>
                    </li>
                    <li>
                        <p>The right to object. You have the right to object to our processing of your personal information.</p>
                    </li>
                    <li>
                        <p>The right of restriction. You have the right to request that we restrict the processing of your personal information.</p>
                    </li>
                    <li>
                        <p>The right to data portability. You have the right to be provided with a copy of the personal information we have on you in a structured, machine-readable and commonly used format.</p>
                    </li>
                    <li>
                        <p>The right to withdraw consent. You also have the right to withdraw your consent at any time where we relied on your consent to process your personal information.</p>
                    </li>
                </ul>
                    <p>In order make a request regarding your personal information, please contact FF Data by email at data@agnify.io.</p>
                    <p>If you have a comment, question, or complaint about how we are handling your personal information, you can contact us at data@agnify.io</p>
                    <p>
                        In addition, if you are located in the EEA, you may submit a complaint regarding the processing of your personal information to the EU data protection authorities (each a “DPA”). The following link may assist you in finding the appropriate DPA: <a href="https://ec.europa.eu/justice/dataprotection/bodies/authorities/index_en.htm">https://ec.europa.eu/justice/dataprotection/bodies/authorities/index_en.htm.</a>
                    </p>
                    <p>
                        <strong>Legal Basis for Processing under GDPR</strong>
                    </p>
                    <p>If you are located in the EEA, our legal basis for collecting and using the personal information described in this Policy depends on the personal information we collect and the specific context in which we collect it.</p>
                    <p>We may process personal information because:</p>
                    <ul>
                        <li>
                            <p>We need to perform a contract with you;</p>
                        </li>
                        <li>
                            <p>You have given us consent to do so;</p>
                        </li>
                        <li>
                            <p>The processing is in our legitimate interest to offer the Service, when that legitimate interest is not overridden by your rights;</p>
                        </li>
                        <li>
                            <p>To comply with the law.</p>
                        </li>
                    </ul>
                    <p>Where personal information is processed based on consent, EU residents have the right to withdraw such consent at any time. To do so, please contact us as described in this Policy. If there is a different legal basis that would permit us to continue processing your personal information after withdrawing consent, we will notify you of that legal basis at the time of your request.</p>
                    <p>
                        <strong>South African Privacy Rights</strong>
                    </p>
                    <p>If you are a South African resident, South Africa law may provide you with certain rights with regard to your personal information under the Protection of Personal Information Act (“POPIA”) and Promotion of Access to Information Act (“PAIA”).as well the Consumer Protection Act Throughout this Privacy Policy you will find information required by POPIA regarding the categories of personal information collected from you; the purposes for which we use personal information, and the categories of third parties your data may be shared with. This information is current as of the date of the Policy and is applicable in the 12 months preceding the effective date of the Policy.</p>
                    <p>As a South African resident, the POPIA and PAIA provide you the ability to make inquiries regarding to your personal information. Specifically, the degree to which the information is not already provided in this Privacy Policy, you have the right to request disclosure or action your personal information, including:</p>
                    <ul>
                        <li>
                            <p>If your personal information is collected by us.</p>
                        </li>
                        <li>
                            <p>The specific pieces of personal information collected about you.</p>
                        </li>
                        <li>
                            <p>The ability to correct or delete certain personal information collected about you.</p>
                        </li>
                        <li>
                            <p>The ability to delete all the personal information collected about you, subject to certain exceptions.</p>
                        </li>
                        <li>
                            <p>To opt-in or opt-out of direct marketing to you.</p>
                        </li>
                        <li>
                            <p>To object to processing of your personal information, or</p>
                        </li>
                        <li>
                            <p>Appeal any rejection of access to your personal information</p>
                        </li>
                    </ul>

                    <p>
                        You may submit a request regarding your rights under POPIA or PAIA by submitting a request through the following form <a href="https://inforegulator.org.za/wp-content/uploads/2020/07/FORM-2-REQUEST-FOR-CORRECTION-OR-DELETION-OF-PERSONAL-INFORMATION-OR.pdf" >here</a>or by contacting us at one of the following:
                    </p>
                    <p>Email data@agnify.io</p>
                    
                    <p>If we receive a POPIA request from you, we will first make a determination regarding the applicability of the law, and we will then take steps to verify your identity prior to responding. The steps to verify your identity may vary based on our relationship with you, but, at a minimum, it will take the form of confirming and matching the information submitted in the request with information already held by FF Data and/or contacting you through previously used channels to confirm that you submitted the request (i.e. confirming identity through contact information that we have on file, and/or the contact information submitted to make the request).</p>
                    <p>
                        FF Data does not collect or process the special personal information such as your religious or philosophical beliefs, race or ethnic origins, trade union memberships, political persuasion, health or sex life, or your criminal behaviour or biometric information.</p>
                    <p>If you are located in the Republic of South Africa, you may submit a complaint regarding the processing of your personal information to the Information Regulator at the following link:                    
                        <a href="https://www.justice.gov.za/inforeg/contact.html"> https://www.justice.gov.za/inforeg/contact.html.</a>
                    </p>
                    <p>
                        <strong>Information Storage and Security</strong>
                    </p>
                    <p>We employ industry-standard and/or generally accepted security measures designed to secure the integrity and confidentiality of all information submitted through the Service. However, the security of information transmitted through the internet or via a mobile device can never be guaranteed. We are not responsible for any interception or interruption of any communications through the internet or for changes to or losses of data.</p>
                    <p>Users of the Service are responsible for maintaining the security of any password, user ID or other form of authentication involved in obtaining access to password protected or secure areas of the Service. In order to protect you and your information, we may suspend your use of any of the Service, without notice, pending an investigation, if any breach of security is suspected.</p>
                    <p>
                        <strong>External Links</strong>
                    </p>
                    <p>The Service may contain links to other websites maintained by third parties. Please be aware that we exercise no control over linked sites and FF Data is not responsible for the privacy practices or the content of such sites. Each linked site maintains its own independent privacy and data collection policies and procedures, and you are encouraged to view the privacy policies of these other sites before providing any personal information.</p>
                    <p>You hereby acknowledge and agree that FF Data is not responsible for the privacy practices, data collection policies and procedures, or the content of such third-party sites, and you hereby release FF Data from any and all claims arising out of or related to the privacy practices, data collection policies and procedures, and/or the content of such third-party sites.</p>
                    <p>
                        <strong>Children’s Privacy</strong>
                    </p>
                    <p>The Service does not include any adult (18+) content, is not intended for users under 18, and FF Data does not knowingly collect personal information from children under 18.</p>
                    <p>
                        <strong>Changes to this Privacy Policy</strong>
                    </p>
                    <p>FF Data reserves the right to modify this Privacy Policy from time to time in order that it accurately reflects the regulatory environment and our data collection principles. When material changes are made to this Privacy Policy, FF Data will post the revised Policy on our website.</p>
                    <p>This Privacy Policy was last modified as of November 2024.</p>
                </div>
            </div>
        </main>
    </body>
</html>`;