import React, { useState, useEffect } from "react";
import { Divider, Typography } from "@mui/material";
import {
  getNdviMetadata,
  getNdviParameterRange,
} from "../../services/api-mt-service";

export interface ParameterRange {
  Label: string;
  Value: number;
  Max: number;
  Color: string;
}

interface NDVIParameterRangeProps {
  fieldId: string;
  eosViewId: string;
}

const NDVIParameterRange: React.FC<NDVIParameterRangeProps> = ({
  fieldId,
  eosViewId,
}) => {

  const [ndviRanges, setNdviRanges] = useState<ParameterRange[]>([]);
  const [metadata, setMetadata] = useState<{
    Max: number;
    Value: number;
    Min: number;
    Cloud: number;
  } | null>(null);

  useEffect(() => {
    const fetchNdviData = async () => {
        
        try {
          const rangesResponse = await getNdviParameterRange();
          if (Array.isArray(rangesResponse.data)) {
            // Reverse the data
            const reversedRanges = [...rangesResponse.data].reverse();
            //getNDVIData(Number(fieldId), eosViewId, 16, reversedRanges);
            setNdviRanges(reversedRanges);

          } else {
            console.error("Expected an array but received:", rangesResponse.data);
          }

          const metadataResponse = await getNdviMetadata(
            Number(fieldId),
            eosViewId
          );

          setMetadata(metadataResponse.data);
         
        } catch (error) {
          console.error("Failed to fetch NDVI data:", error);
        }
    };
    fetchNdviData();
  }, [fieldId, eosViewId]);

  if (ndviRanges.length === 0) return null;

  const dh = 224 / ndviRanges.length;

  return (
    <div
      id="param-legend"
      style={{
        width: 140,
        //height: 440,
        padding: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div style={{ height: 265 }}>
      
        <Typography
          sx={{
            color: "#444",
            fontSize: 12,
            fontWeight: 600,
          }}
        >
        NDVI
        </Typography>

        <div style={{ position: "relative", fontSize: 10 }}>
          <div style={{ color: "#888", position: "absolute", top: 2 }}>
            <div key={0} style={{ height: `${dh}px` }}> { Number(ndviRanges[0].Max).toFixed(2) } </div>
              {ndviRanges.map((r, ri) => (
                <div key={ri+1} style={{ height: `${dh}px` }}> { Number(r.Value).toFixed(2) } </div>
              ))}
            </div>

          <div style={{ left: 28, position: "absolute", top: 9 }}>
            {ndviRanges.map((r, ri) => (
              <div key={ri}>
                {ri === 0 && (
                  <div
                    style={{
                      width: 20,
                      height: dh / 2,
                      backgroundColor: r.Color,
                    }}
                  />
                )}
                {ri < ndviRanges.length - 1 && (
                  <div
                    style={{
                      width: 20,
                      height: dh,
                      background: `linear-gradient(${r.Color}, ${
                        ndviRanges[ri + 1].Color
                      })`
                    }}
                  />
                )}
                {ri === ndviRanges.length - 1 && (
                  <div
                    style={{
                      width: 20,
                      height: dh / 2,
                      backgroundColor: r.Color,
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div id="param-values" style={{ height: 85 }}>
      <Divider />

      
      {metadata && metadata[0] && (
        <table style={{ fontSize: "12px", color: "#888888", marginTop: 2 }}>
        <tbody>
        {typeof metadata[0].ThirdQuartile !== 'undefined' && (
          <tr>
            <td style={{ width: "40px", padding: 0}}>
              Max:
            </td>  
            <td style={{ width: "80px", padding: 0}}>
              {Number(metadata[0].ThirdQuartile).toFixed(3)}
            </td>
          </tr>
        )}  
        {typeof metadata[0].Mean !== 'undefined' && (
          <tr>
            <td style={{ width: "40px", fontWeight: 600, padding: 0}}>
              Mean:
            </td>  
            <td style={{ width: "80px", padding: 0}}>
              {Number(metadata[0].Mean).toFixed(3)}
            </td>
          </tr>
        )}
        {typeof metadata[0].Minimum !== 'undefined' && (
          <tr>
            <td style={{ width: "40px", padding: 0}}>
              Min:
            </td>  
            <td style={{ width: "80px", padding: 0}}>
              {Number(metadata[0].Minimum).toFixed(3)}
            </td>
          </tr>
        )}
        {typeof metadata[0].Cloud !== 'undefined' && (
          <tr>
            <td style={{ width: "40px", padding: 0}}>
              Cloud:
            </td>  
            <td style={{ width: "80px", padding: 0}}>
              {Number(metadata[0].Cloud).toFixed(1)}%
            </td>
          </tr>
        )}
        </tbody>
        </table>
        )}
      
      {!(metadata && metadata[0]) && (
        <table style={{ fontSize: "12px", color: "#888888", marginTop: 2 }}>
        <tbody>
          <tr>
            <td style={{ width: "40px", padding: 0}}>
              Max:
            </td>  
            <td style={{ width: "80px", padding: 0}}>
              n/a
            </td>
          </tr>
          <tr>
            <td style={{ width: "40px", fontWeight: 600, padding: 0}}>
              Mean:
            </td>  
            <td style={{ width: "80px", padding: 0}}>
              n/a
            </td>
          </tr>
          <tr>
            <td style={{ width: "40px", padding: 0}}>
              Min:
            </td>  
            <td style={{ width: "80px", padding: 0}}>
              n/a
            </td>
          </tr>
          <tr>
          <td style={{ width: "40px", padding: 0}}>
            Cloud:
          </td>  
          <td style={{ width: "80px", padding: 0}}>
            n/a
          </td>
        </tr>
        </tbody>
        </table>
      )}  
        
      </div>

      <div style={{ height: 2 }}>
        <Divider />
      </div>

    </div>        

  );
};

export default NDVIParameterRange;
