import React, { useEffect, useState } from "react";
//import { round } from "mathjs"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Button,
  CircularProgress,
  Grid,
  Card,
} from "@mui/material";
import FFheader from "../../assets/logos/headerReport.png";
import FFfooter from "../../assets/logos/footerReport.png";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Link,
  Image,
} from "@react-pdf/renderer";

interface SummaryProps {
  selectedCategories: string[];
  selectedFields: string[];
  dateRange: string;
  activities: any[],
  date: string;
  totalCost: number;
  orgName: string | undefined;
}


const screenStyles = StyleSheet.create({
  tableCell: {
    fontSize: 16,
    width: "50%",
  },
  tableCellAlignRight: {
    fontSize: 16,
    textAlign: "right",
    width: "50%",
  },
});   

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
  },
  paddedContainer: {
    padding: "20px 50px 0 60px",
  },
  titleHeader: {
    flexDirection: "row",
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
    marginLeft: 10,
    fontWeight: "bold",
    color: "#333",
  },
  caption: {
    fontSize: 10,
    marginTop: 2,
    marginBottom: 5,
    textAlign: "right",
    color: "#666",
  },
  headerImage: {
    width: "100%",
    height: 90,
    marginBottom: 10,
  },
  footerImage: {
    width: "100%",
    height: 78,
    marginTop: 10,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 300,
    marginBottom: 5,
  },
  chipContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 5,
  },
  chip: {
    backgroundColor: "#e0e0e0",
    borderRadius: 16,
    padding: "5px 10px",
    margin: "0 4px 4px 0",
    fontSize: 12,
  },
  table: {
    width: "100%",
    marginTop: 15,
  },
  tableHeaderRow: {
    flexDirection: "row",
    marginTop: 20,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderCell: {
    flex: 1,
    padding: 8,
    fontSize: 12,
    fontWeight: "bold",
    color: "#333",
    backgroundColor: "#e0e0e0",
  },
  tableHeaderCellAlignRight: {
    flex: 1,
    padding: 8,
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "right",
    color: "#333",
    backgroundColor: "#e0e0e0",
  },
  tableCell: {
    flex: 1,
    padding: 8,
    fontSize: 12,
    color: "#333",
    borderBottom: "0.8px solid #e0e0e0",
  },
  tableCellAlignRight: {
    flex: 1,
    padding: 8,
    fontSize: 12,
    textAlign: "right",
    color: "#333",
    borderBottom: "0.8px solid #e0e0e0",
  },
  tableFooterCell: {
    flex: 1,
    padding: 8,
    fontSize: 12,
    fontWeight: "bold",
    color: "#333",
    backgroundColor: "#e0e0e0",
  },
  tableFooterCellAlignRight: {
    flex: 1,
    padding: 8,
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "right",
    color: "#333",
    backgroundColor: "#e0e0e0",
  },
  footer: {
    marginTop: "auto",
    fontSize: 12,
    textAlign: "center",
  },
  link: {
    color: "#3C4F1E",
    textDecoration: "underline",
    fontStyle: "italic",
  },
  pagination: {
    fontSize: 10,
    textAlign: "right",
    color: "#333",
  },
  reportHeader: {
    flexDirection: "row",
    fontSize: 12,
    color: "#333",
    padding: 8,
  }, 
  reportHeaderTitle: {
    fontSize: 12,
    color: "#333",
    width: 90,
  }, 
  reportHeaderItem: {
    fontSize: 12,
    color: "#333",
  }, 
  bottomMargin: {
    height: 30,
  }
});

const ActivitySummaryReport: React.FC<SummaryProps> = ({
  dateRange,
  activities,
  selectedCategories,
  selectedFields,
  date,
  orgName,
}) => {

  const [activitiesDisplay, setActivitiesDisplay] = useState<any[]>([]);
  const [totalCost, setTotalCost] = useState<number>(0);

  useEffect(() => {

    if(activities && activities.length > 0) {

      let catsHelper: string[] = [];
      let cats: any[] = [];
      let total: number = 0;
    
      activities.forEach(activity => {
        if(catsHelper.indexOf(activity.activityCategory) === -1) {
          catsHelper.push(activity.activityCategory);
          cats.push({activityCategory: activity.activityCategory, subtotal: 0});
        }
        activity.activityDetails.forEach(detail => {
          total += detail.cost;
        });
      });
      cats.sort((a, b) => a.activityCategory > b.activityCategory ? 1 : -1);

      activities.forEach(activity => {
        cats.forEach(function(cat, index) {
          if(cat.activityCategory === activity.activityCategory) {
            activity.activityDetails.forEach(detail => {
              cat.subtotal += detail.cost;
            });
          }
        });
      });
      setActivitiesDisplay(cats);
      setTotalCost(Math.round(total));
    }
  }, [activities]);



  return (
    <div
    style={{ 
      position: "absolute",
      top: 224,
      height: "calc(100vh - 280px)",
      padding: 0,
      width: "calc(100vw - 288px)",
    }}
    >    

      <Grid container spacing={2}>
        <Grid item xs={10}>
            <TableContainer component={Card} style={{height: "calc(100vh - 280px)", overflowY: "scroll", }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{fontSize: 16,fontWeight: "bold", backgroundColor: "#ffffff", width: "50%",position: "sticky", top: 0, }}>Category</TableCell>
                    <TableCell style={{fontSize: 16,fontWeight: "bold", backgroundColor: "#ffffff", width: "50%",position: "sticky", textAlign: "right", top: 0, }}>Cost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {activitiesDisplay?.map((activity, activityIndex) =>
                    <TableRow key={`${activityIndex}`} hover>
                      <TableCell style={screenStyles.tableCell}>{activity.activityCategory}</TableCell>
                      <TableCell style={screenStyles.tableCellAlignRight}>R {Math.round(activity.subtotal)}</TableCell>
                    </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell style={{fontSize: 16,fontWeight: "bold", backgroundColor: "#ffffff", width: "50%",position: "sticky", bottom: 0, }}>Total</TableCell>
                  <TableCell style={{fontSize: 16,fontWeight: "bold", backgroundColor: "#ffffff", width: "50%",position: "sticky", textAlign: "right", bottom: 0, }}>R {totalCost}</TableCell>
                </TableRow> 
              </TableFooter>
            </Table>
          </TableContainer>

        </Grid>
        <Grid item xs={2}>
            <PDFDownloadLink
              document={
                <MyDocument
                  selectedCategories={selectedCategories}
                  selectedFields={selectedFields}
                  dateRange={dateRange}
                  activities={activitiesDisplay}
                  date={date}
                  totalCost={totalCost}
                  orgName={orgName}
                />
              }
              fileName="Activity_Summary_Report.pdf"
            >
              {({ blob, url, loading, error }) => (
                <Button
                  sx={{ marginTop: 1 }}
                  variant="contained"
                  color="primary"
                  startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                  {loading ? "Loading..." : "Download PDF"}
                </Button>
              )}
            </PDFDownloadLink>
        </Grid>
      </Grid>



    </div>
  );
};

const MyDocument: React.FC<SummaryProps> = ({
  selectedCategories,
  selectedFields,
  dateRange,
  activities,
  date,
  totalCost,
  orgName,
}) => (

  <Document>
    <Page size="A4" style={styles.page} wrap={true}>
      <Image style={styles.headerImage} src={FFheader} fixed/>
      <View style={styles.paddedContainer}>


        <Text style={styles.pagination} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
        )} fixed>
        </Text>  

        <Text style={styles.title}>Activity Summary Report</Text>

        <View style={styles.reportHeader}>
          <Text style={styles.reportHeaderTitle}>Organisation: </Text>
          <Text style={styles.reportHeaderItem}>{orgName}</Text>
        </View>
        <View style={styles.reportHeader}>
          <Text style={styles.reportHeaderTitle}>Report Period: </Text>
          <Text style={styles.reportHeaderItem}>{dateRange}</Text>
        </View>
        <View style={styles.reportHeader}>
          <Text style={styles.reportHeaderTitle}>Report Date: </Text>
          <Text style={styles.reportHeaderItem}>{date}</Text>
        </View>


        <View style={styles.table}>
          <View style={styles.tableHeaderRow} fixed>
            <Text style={styles.tableHeaderCell}>Category</Text>
            <Text style={styles.tableHeaderCellAlignRight}>Cost</Text>
          </View>
          {activities?.map((activity, activityIndex) =>
              <View
                key={`${activityIndex}`}
                style={styles.tableRow}
                break={activityIndex > 5}
                wrap={false}
              >
                <Text style={styles.tableCell}>{activity.activityCategory}</Text>
                <Text style={styles.tableCellAlignRight}>R {Math.round(activity.subtotal)}</Text>
              </View>
          )}
          <View style={styles.tableRow}>
            <Text style={styles.tableFooterCell}>Total</Text>
            <Text style={styles.tableFooterCellAlignRight}>R {totalCost}</Text>
          </View>

        </View>

      </View>
      
      <View style={styles.bottomMargin} fixed></View>    
      
      <Text style={styles.footer} fixed>
        Like what you see? Visit{" "}
        <Link src="http://farmersfriend.co/" style={styles.link}>
          Farmers Friend
        </Link>{" "}
        today.
      </Text>
      <Image style={styles.footerImage} src={FFfooter} fixed/>
      
    </Page>
  </Document>

);

export default ActivitySummaryReport;
