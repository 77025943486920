import React, { useRef } from 'react';
import { Grid, Typography, Button, MenuItem, Box, CircularProgress, AlertColor } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { useConfig } from "../../context/ConfigContextTest";
import TextBox from "../atom/textBox";
import CustomSnackbar from "../atom/CustomSnackbar";

import { sendContactEmail } from '../../services/api-ffm-service';

import theme from "../../theme";

const ContactUsPage = () => {
  const { selectedOrganization } = useConfig();
  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      subject: '',
      message: ''
    }
  });

  const snackbarRef = useRef<{ handleOpen: (title: string, msg: string | null, sev: AlertColor) => void }>(null);

  if (!selectedOrganization) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const onSubmit = async (data: { subject: string; message: string }) => {
    const contactPerson = selectedOrganization.contactDetail[0];
    const emailContact = contactPerson?.contacts.find(contact => contact.type === "Email");
    const mobileContact = contactPerson?.contacts.find(contact => contact.type === "Mobile");

    const emailData = {
      subject: data.subject,
      message: data.message,
      organization: selectedOrganization.name,
      contactPerson: contactPerson ? `${contactPerson.name} ${contactPerson.surname}` : 'Not provided',
      contactNumber: mobileContact ? mobileContact.details : 'Not provided',
      contactEmail: emailContact ? emailContact.details : 'Not provided'
    };

    try {
      await sendContactEmail(emailData);
      snackbarRef.current?.handleOpen(
        "Message Sent",
        "Your message has been successfully sent to the Farmers Friend Support Team.",
        "success"
      );
      reset();
    } catch (error) {
      console.error('Error sending email:', error);
      snackbarRef.current?.handleOpen(
        "Error",
        "Failed to send your message. Please try again later.",
        "error"
      );
    }
  };


  const subjectOptions = [
    { value: "General Enquiry", label: "General Enquiry" },
    { value: "Technical Support", label: "Technical Support" },
    { value: "Suggestion", label: "Suggestion" }
  ];

  //<Typography sx={{ mt: 4, ml: 2, fontSize: 18, fontWeight: 500 }}>

  return (
    <Grid container>


      <Box sx={{ p: 3, maxWidth: 1200, margin: 'auto' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%" p={3}>
              <Typography variant="h6" gutterBottom align="left" sx={{ fontWeight: 'bold' }}>
                HAVE A QUESTION OR WOULD LIKE TO GET IN TOUCH!
              </Typography>
              <Typography variant="body1" paragraph align="left">
                Complete the form to get in touch with your Farmers Friend team, send a message and we will get back to you.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>



            <Box p={3} borderRadius={2}>

            <Grid item xs={12} sx={{ pb: 2 }}>
                <Typography variant="h6" >
                    Contact Us
                </Typography>
            </Grid>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      name="subject"
                      control={control}
                      rules={{ required: "Subject is required" }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextBox
                          select
                          id="subject"
                          label="Subject"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!errors.subject}
                          helperText={errors.subject?.message}
                          inputRef={ref}
                          fullWidth
                          sx={{ bgcolor: 'white' }}
                        >
                          {subjectOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextBox>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="message"
                      control={control}
                      rules={{ 
                        required: "Message is required",
                        maxLength: { value: 250, message: "Message cannot exceed 250 characters" }
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextBox
                          multiline
                          rows={4}
                          id="message"
                          label="Message"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!errors.message}
                          helperText={errors.message?.message || `${value.length}/250 characters`}
                          inputRef={ref}
                          fullWidth
                          sx={{ bgcolor: 'white' }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button 
                      type="submit" 
                      variant="contained" 
                      fullWidth
                      sx={{ 
                        mt: 2, 
                        backgroundColor: theme.palette.secondary.main,
                        '&:hover': { backgroundColor: theme.palette.primary.main, },
                        height: '48px',
                        fontSize: '16px'
                      }}
                    >
                      SEND MESSAGE
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
        <CustomSnackbar ref={snackbarRef} />
      </Box>
    </Grid>
  );
};

export default ContactUsPage;