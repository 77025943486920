import React, { useState } from "react";
import {
  Box,
  Button,
  CssBaseline,
  IconButton,
  Link,
  Toolbar,
  useTheme,
  useMediaQuery,
  Typography,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import FFlogo from "../../assets/logos/fflogoGreen.png";
import ApplicationsMenu from "../molecules/appMenu";
import { loginRequest } from "../../auth-config";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { UserProfileForm } from "./profileSettings";
import QuickAdd from "../atom/quickAdd";
import { Logout } from "@mui/icons-material";
import { useConfig } from "../../context/ConfigContextTest";

const Header = () => {
  const theme = useTheme();
  const { instance } = useMsal();
  const { selectedOrganization, clearAllConfig } = useConfig();
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const activeAccount = instance.getActiveAccount();
  
  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    setOpenDialog(true);
  };

  const handleLogoutConfirm = () => {
    setIsLoggingOut(true);
    setOpenDialog(false);
    
    clearAllConfig();
    instance.setActiveAccount(null);
    
    const logoutRequest = {
      account: activeAccount,
      postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
      onRedirectNavigate: (url) => {
        return false;
      },
    };
    instance.logoutRedirect(logoutRequest).finally(() => {
      setIsLoggingOut(false);
    });
  };

  const handleLogoutCancel = () => {
    setOpenDialog(false);
  };

  const isSmScreen = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box>
      <CssBaseline />
      <MuiAppBar
        sx={{
          backgroundColor: theme.palette.common.white,
          height: "50px",
        }}
        position="fixed"
      >
        <Toolbar variant="dense" sx={{ minHeight: '50px' }}>
          <ApplicationsMenu />
          <Link href="/" underline="none" sx={{ mr: 1 }}>
            <img src={FFlogo} alt="FFlogo" height={"30px"} width={"30px"} />
          </Link>
          {isSmScreen && (
            <Box flexGrow={1}>
              <Link
                href="/"
                color={theme.palette.primary.main}
                variant="h6"
                marginLeft={1}
                underline="none"
              >
                Farmers Friend
              </Link>
            </Box>
          )}
          {selectedOrganization && (
            <>
              <QuickAdd />
              {/* <SearchBar /> */}
            </>
          )}
          <AuthenticatedTemplate>
            <UserProfileForm />
            <Tooltip title="Log Out">
              <IconButton
                aria-label="exit"
                sx={{
                  color: theme.palette.primary.main,
                  width: "30px",
                  height: "30px",
                }}
                onClick={handleLogoutRedirect}
                disabled={isLoggingOut}
              >
                <Logout fontSize="small" />
              </IconButton>
            </Tooltip>
            <Dialog
              open={openDialog}
              onClose={handleLogoutCancel}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Confirm Logout"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to log out?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleLogoutCancel} color="primary">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleLogoutConfirm}
                  color="primary"
                  autoFocus
                >
                  Logout
                </Button>
              </DialogActions>
            </Dialog>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Button
              variant="outlined"
              onClick={handleLoginRedirect}
              sx={{ maxHeight: "25px" }}
            >
              <Typography noWrap>Sign in</Typography>
            </Button>
          </UnauthenticatedTemplate>
        </Toolbar>
      </MuiAppBar>
      <Toolbar variant="dense" sx={{ minHeight: '50px' }} />
    </Box>
  );
};

export default Header;
