import axios from "axios";
import { WeatherApiResponse } from "../models/weatherForecast.interface";

const api = axios.create({
  baseURL: process.env.REACT_APP_WS_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    code: process.env.REACT_APP_WS_API_KEY,
  },
});

const code = process.env.REACT_APP_WS_API_KEY;
const source: string = process.env.REACT_APP_ENVIRONMENT || "";
export const getWeather = async (
  FieldId: number,
  FromDate: string,
  ToDate: string,
  Parameters: string
): Promise<any> => {
  try {

    const response = await api.get<any>("/eos-weather-access-func", {
      params: { FieldId, FromDate, ToDate, Parameters, source, code },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch weather: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error("Something went wrong while fetching weather", error);
      return null;
    }
  }
};

export const getRecentWeather = async (
  FieldId: number,
  Parameters: string
): Promise<any> => {
  try {
    const response = await api.get<any>("/eos-recent-weather-access-func", {
      params: { FieldId, Parameters, source, code },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch weather: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error("Something went wrong while fetching weather", error);
      return null;
    }
  }
};

export const getForecastWeather = async (
  lat: number,
  lon: number
): Promise<any | null> => {
  try {
    const response = await axios.get<WeatherApiResponse>(
      'https://api.met.no/weatherapi/locationforecast/2.0/compact',
      {
        params: { lat, lon },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch forecast weather: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error('Something went wrong while fetching forecast weather', error);
      return null;
    }
  }
};