import React, { useState, useEffect, useRef } from "react";
import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-kanban";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  getActivities,
  updateActivityStatus,
  updateActivity,
  deleteActivity,
} from "../../../services/api-ffm-service";
import { fetchData } from "../../../hooks/useFetchData";
import "./overview.css";
import ActivityDialog from "../../organisms/activityDialog";
import { Status } from "../../../models/status.interface";
import { CircularProgress, Box, AlertColor } from "@mui/material";
import GenericConfirmDialog from "../genericConfirmDialog";
import CustomSnackbar from "../../atom/CustomSnackbar";
import { handleApiResponse } from "../../../utils/Utilities";
import { useConfig } from "../../../context/ConfigContextTest";

registerLicense(
  "ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Ud0BjWnpfdHBcRGRU"
);

interface Task {
  Id: number;
  Title: string;
  Status: string;
  Summary: string;
  Type: string;
  Priority: string;
  Tags: string;
  Estimate: number;
  Assignee: string;
  RankId: number;
  Color: string;
  ClassName: string;
  activity: any;
}

const KanbanBoard = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const { activityStatuses } = useConfig();
  const [activities, setActivities] = useState<any[]>([]);
  const { selectedOrganization } = useConfig();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const snackbarRef = useRef<{ handleOpen: (title: string, msg: string | null, sev: AlertColor) => void }>(null);
  const [confirmDoneOpen, setConfirmDoneOpen] = useState(false);

  useEffect(() => {
    fetchDataAsync();
  }, [selectedOrganization?.organizationId]);

  useEffect(() => {
    if (activities.length > 0 && activityStatuses.length > 0) {
      const transformedData = activities.map((activity) => ({
        Id: activity.activityId,
        Title: activity.name + " - " + activity.activityId,
        Status: activity.status,
        Summary: activity.description,
        Type: "Task",
        Priority: "Normal",
        Tags: activity.category,
        Estimate: 1,
        Assignee: activity.assignedTo,
        RankId: activity.activityId,
        Color: "#506135 ",
        ClassName: "e-task, e-normal, e-assignee",
        activity: activity,
      }));
      setTasks(transformedData);
    }
  }, [activities, activityStatuses]);

  const fetchDataAsync = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        fetchData(getActivities, setActivities, undefined, [
          selectedOrganization?.organizationId ?? 0,
        ]),
      ]);
      
      setIsDataFetched(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = () => {
    setSelectedTask(selectedTask);
    setConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    let response;

    if (selectedTask) {
      try {
        response = await deleteActivity(selectedTask.activity.activityId);
        if (response.status === 200) {
          setTasks(
            tasks.filter(
              (task) =>
                task.activity.activityId !== selectedTask.activity.activityId
            )
          );
        }
      } catch (error) {
        console.error("Failed to delete activity:", error);
      }

      closeModal();
    }

    handleApiResponse(response, showSnackbar);
  };

  const handleConfirmDone = async () => {
    setConfirmDoneOpen(false);
    if (selectedTask) {
      const statusUpdate: Status = {
        statusId: activityStatuses.find((status) => status.name === "Done")?.statusId || 0,
        activityId: selectedTask.Id,
        azureUserId: selectedOrganization?.azureUserId ?? "",
      };
  
      await updateTaskStatus(statusUpdate);
    }
  };
  

  const handleDragStop = async (args) => {
    const { data } = args;
    const movedTask = data[0];
  
    const newStatusObj = activityStatuses.find(
      (status) => status.name === movedTask.Status
    );

    if (!newStatusObj) {
      console.error("Error: New Status not found");
      return;
    }
  
    const endDate = new Date(movedTask.activity.endDate);
    const today = new Date();
  
    const previousStatus = movedTask.activity.status;
  
    if (newStatusObj.name === "Done") {
      if (endDate > today) {
        movedTask.activity.status = previousStatus;
        movedTask.Status = previousStatus;
        setTasks([...tasks]);
  
        if (snackbarRef.current) {
          snackbarRef.current.handleOpen(
            "Status update failed!",
            "Activity status cannot be set to DONE if the end date is future dated.",
            "warning"
          );
        }
      } else {
        setSelectedTask(movedTask);
        setConfirmDoneOpen(true);
      }
      return;
    }
  
    const statusUpdate: Status = {
      statusId: newStatusObj.statusId,
      activityId: movedTask.Id,
      azureUserId: selectedOrganization?.azureUserId ?? "",
    };
  
    await updateTaskStatus(statusUpdate);

  };

  const updateTaskStatus = async (statusUpdate: Status) => {
    try {
      await updateActivityStatus(statusUpdate);
      await fetchDataAsync(); 

    } catch (error) {
      console.error("Error updating task status:", error);
    }
  };
  

  const handleCardClick = (args) => {
    setSelectedTask(args.data);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setConfirmOpen(false);
    setSelectedTask(null);
  };

  const showSnackbar = (title, message, severity) => {
    if (snackbarRef.current) {
      snackbarRef.current.handleOpen(title, message, severity);
    }
  };

  const handleFormSubmit = async (formData) => {
    formData.partyId = selectedOrganization?.partyId;
    formData.statusId = formData.activityStatusId;
    let response;

    try {
      if (selectedTask) {
        const endDate = new Date(selectedTask.activity.endDate);
        const today = new Date();

        const doneStatusId = activityStatuses.find((status) => status.name === "Done")?.statusId;

        if (formData.activityStatusId === doneStatusId && endDate > today) {
          if (snackbarRef.current) {
            snackbarRef.current.handleOpen(
              "Status update failed!",
              "Activity status cannot be set to DONE if the end date is future dated.",
              "warning"
            );
          }
        } //else if (formData.activityStatusId === doneStatusId) {
          //handleOpenActivityDialog();
          //return;
        //} 
        else {
          response = await updateActivity(formData);
          if (response.status === 200) {
            await fetchDataAsync();
          }
        }
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        if (snackbarRef.current) {
          snackbarRef.current.handleOpen(
            error.response.details,
            error.response.message,
            "success"
          );
        }
      }
    }

    closeModal();
    handleApiResponse(response, showSnackbar);
  };

  const handleOpenActivityDialog = () => {
    setConfirmDoneOpen(false);
    setShowModal(true); 
  };
  

  return (
    <>
      <CustomSnackbar ref={snackbarRef} />
      {!isLoading && (
        <Box margin={2}>
          <div className="kanban-control-section">
            <div className="col-lg-12 control-section">
              <div className="control-wrapper">
                {isDataFetched ? (
                  <KanbanComponent
                    id="kanban"
                    keyField="Status"
                    dataSource={tasks}
                    cardSettings={{
                      contentField: "Summary",
                      headerField: "Title",
                      tagsField: "Tags",
                      grabberField: "Color",
                      footerCssField: "Assignee",
                    }}
                    dragStop={handleDragStop}
                    cardClick={handleCardClick}
                  >
                  <ColumnsDirective>
                      {activityStatuses.map((status) => (
                        <ColumnDirective
                          key={status.statusId}
                          headerText={status.name}
                          keyField={status.name}
                        />
                      ))}
                    </ColumnsDirective>
                  </KanbanComponent>
                ) : (
                  <CircularProgress color="primary" />
                )}
              </div>
            </div>
          </div>
          <ActivityDialog
            isOpen={showModal}
            onClose={closeModal}
            onSubmit={handleFormSubmit}
            handleDelete={handleDelete}
            formData={selectedTask?.activity}
          />
          <GenericConfirmDialog
            open={confirmDoneOpen}
            onCancel={handleOpenActivityDialog}
            onConfirm={handleConfirmDone}
            title="Confirm Task Completion"
            content="Are you sure all data is correct before setting the status to Done?"
            cancelText="Update Activity"
            confirmText="Activity Done"
          />
          <GenericConfirmDialog
            open={confirmOpen}
            onCancel={() => setConfirmOpen(false)}
            onConfirm={handleConfirmDelete}
            title="Confirm Deletion"
            content="Are you sure you want to delete this activity?"
          />
        </Box>
      )}
    </>
  );
};

export default KanbanBoard;
