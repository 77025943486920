import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useMsal } from "@azure/msal-react";
import { Organization } from '../interfaces/organization.interface';
import { NoteType } from '../interfaces/noteType.interface';
import { ActivityCategory } from '../interfaces/activityCategory.interface';
import { DocumentType } from '../interfaces/documentType.interface';
import { LegalEntity } from '../interfaces/legalEntity.interface';
import { Field } from '../interfaces/field.interface';
import { Crops } from '../interfaces/crops.interface';
import { Product } from '../interfaces/product.interface';
import { getFields } from '../services/api-gs-service';
import { getActivityCategories, getActivityStatuses, getDocumentTypes, getLegalEntities, getSeasonStages, getNoteTypes, getOrganizations, setAzureUserId, getFieldIcon } from '../services/api-ffm-service';
import { getCrops, getProducts } from '../services/api-car-service';

interface ConfigContextProps {
  noteTypes: NoteType[];
  mappedfields: Field[];
  seasonStages: any[];
  crops: Crops[];
  products: Product[];
  activityCategories: ActivityCategory[];
  documentTypes: DocumentType[];
  legalEntities: LegalEntity[];
  activityStatuses: any[];
  organizations: Organization[];
  selectedOrganization: Organization | null;
  configLoaded: boolean;
  setSelectedOrganization: (org: Organization | null) => void;
  refreshConfigSettings: () => Promise<void>;
  refreshMappedFields: () => Promise<void>;
  clearAllConfig: () => Promise<void>;
  refreshOrganizations: () => Promise<void>;
}

const ConfigContext = createContext<ConfigContextProps | undefined>(undefined);

interface ConfigProviderProps {
  children: ReactNode;
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const [activityStatuses, setActivityStatuses] = useState<any[]>([]);
  const [noteTypes, setNoteTypes] = useState<any[]>([]);
  const [crops, setCrops] = useState<Crops[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [mappedfields, setMappedFields] = useState<Field[]>([]);
  const [seasonStages, setSeasonStages] = useState<any[]>([]);
  const [activityCategories, setActivityCategories] = useState<ActivityCategory[]>([]);
  const [legalEntities, setLegalEntities] = useState<LegalEntity[]>([]);
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  //const [selectedOrganization, setSelectedOrganizationState] = useState<Organization | null>(null);
  const [configLoaded, setConfigLoaded] = useState(false);

  const [selectedOrganization, setSelectedOrganizationState] = useState<Organization | null>(() => {
    const storedOrg = localStorage.getItem('selectedOrganization');
    return storedOrg ? JSON.parse(storedOrg) : null;
  });

  const { accounts, inProgress } = useMsal();
  const userId = accounts.length > 0 ? accounts[0].localAccountId : '';

  useEffect(() => {
    if (inProgress === "none" && accounts.length > 0) {
      const lastLoggedInUser = localStorage.getItem('lastLoggedInUser');
      const currentUser = accounts[0].localAccountId;

      if (lastLoggedInUser && lastLoggedInUser !== currentUser) {
        localStorage.removeItem('selectedOrganization');
        setSelectedOrganizationState(null);
      }

      localStorage.setItem('lastLoggedInUser', currentUser);
    }
  }, [inProgress, accounts]);

  const refreshMappedFields = async () => {
    try {
      if (!selectedOrganization) return;
      const fieldsData = await getFields(selectedOrganization.partyIdentifier);

      const updatedFields = await Promise.all(fieldsData.map(async (field) => {
        if (field.coords) {

          const fieldIcon = await getFieldIcon(field.farmFieldLinkIdentifier);
          field.fieldIconUrl = fieldIcon;
      
          return {
            ...field
          };
        }
        return field;
      }));
      

      setMappedFields(updatedFields);
      console.log('Configuration Mapped fields Loaded:', {
        fields: mappedfields,
        updatedFields: updatedFields
      });
      
    } catch (error) {
      console.error('Failed to refresh mapped fields:', error);
    }
  };

  const refreshOrganizations = async () => {
    try {
      const organizationsData = await getOrganizations();
      setOrganizations(organizationsData);
  
      if (organizationsData.length > 0 && !selectedOrganization) {
        setSelectedOrganization(organizationsData[0]);
      }
  
      console.log('Organizations refreshed:', organizationsData);
    } catch (error) {
      console.error('Failed to refresh organizations:', error);
    }
  };
  

  // const setSelectedOrganization = (org: Organization | null) => {
  //   if (org && userId) {
  //     org.azureUserId = userId;
  //   }
  //   setSelectedOrganizationState(org);
  // };  

  const setSelectedOrganization = (org: Organization | null) => {
    if (org && userId) {
      org.azureUserId = userId;
    }
    setSelectedOrganizationState(org);
    if (org) {
      localStorage.setItem('selectedOrganization', JSON.stringify(org));
    } else {
      localStorage.removeItem('selectedOrganization');
    }
  }; 

  useEffect(() => {
    if (selectedOrganization) {
      refreshMappedFields();
    }
  }, [selectedOrganization]);

  const clearAllConfig = (): Promise<void> => {
    return new Promise((resolve) => {
      setActivityStatuses([]);
      setNoteTypes([]);
      setCrops([]);
      setProducts([]);
      setMappedFields([]);
      setSeasonStages([]);
      setActivityCategories([]);
      setLegalEntities([]);
      setDocumentTypes([]);
      setOrganizations([]);
      setSelectedOrganizationState(null);
      localStorage.removeItem('selectedOrganization');
      localStorage.removeItem('lastLoggedInUser'); 
      setConfigLoaded(false);
  
      console.log("All configuration data has been cleared.");
  
      resolve();
    });
  };
  
  
  const refreshConfigSettings = async () => {
    try {
      if (!userId) return;
  
      setAzureUserId(userId);
      
      const [
        organizationsData,
        activityStatusesData,
        noteTypesData,
        cropsData,
        productsData,
        seasonStagesData,
        activityCategoriesData,
        legalEntitiesData,
        documentTypesData
      ] = await Promise.all([
        getOrganizations(),
        getActivityStatuses(),
        getNoteTypes(),
        getCrops(),
        getProducts(),
        getSeasonStages(),
        getActivityCategories(),
        getLegalEntities(),
        getDocumentTypes()
      ]);
  
      setOrganizations(organizationsData);
      setActivityStatuses(activityStatusesData);
      setNoteTypes(noteTypesData);
      setCrops(cropsData);
      setProducts(productsData);
      setSeasonStages(seasonStagesData);
      setActivityCategories(activityCategoriesData);
      setLegalEntities(legalEntitiesData);
      setDocumentTypes(documentTypesData);
  
      if (organizationsData.length > 0 && !selectedOrganization) {
        setSelectedOrganization(organizationsData[0]);
      }
  
      setConfigLoaded(true);
  
      console.log('Configuration Loaded:', {
        organizations: organizationsData,
        activityStatuses: activityStatusesData,
        noteTypes: noteTypesData,
        crops: cropsData,
        products: productsData,
        seasonStages: seasonStagesData,
        activityCategories: activityCategoriesData,
        legalEntities: legalEntitiesData,
        documentTypes: documentTypesData,
      });
  
    } catch (error) {
      console.error('Failed to fetch configuration data:', error);
      setConfigLoaded(true);
    }
  };
  

  useEffect(() => {
    if (inProgress === "none" && accounts.length > 0) {
      const loadConfig = async () => {
        await refreshConfigSettings();
        
        if (selectedOrganization) {
          await refreshMappedFields();
        }
      };
  
      loadConfig();
    }
  }, [inProgress, accounts]);

  return (
    <ConfigContext.Provider value={{
      activityStatuses,
      noteTypes,
      crops,
      products,
      mappedfields,
      documentTypes,
      seasonStages,
      activityCategories,
      legalEntities,
      organizations,
      selectedOrganization,
      configLoaded,
      setSelectedOrganization,
      refreshConfigSettings,
      refreshMappedFields,
      refreshOrganizations,
      clearAllConfig,
    }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};