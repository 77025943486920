// utils.js
import { AlertColor } from "@mui/material";

export const formatDate = (dateStr) => {
  if (!dateStr) return "";
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const addPropertyIfNotEmpty = (obj, key, value) => {
  if (value !== null && value !== undefined) {
    obj[key] = value;
  }
};

export const generateGUID = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );
};

export const handleMessage = (event) : any => {
  if (event.origin !== process.env.REACT_APP_MAPPING_TOOL) {
    console.warn("Ignoring message from unexpected origin:", event.origin);
    return;
  }
  const { data } = event;
  if (data.type === "updatedPolygon") {
    return data;
  }
};

export const handleEditMessage = (event) : any => {
  if (event.origin !== process.env.REACT_APP_MAPPING_TOOL) {
    console.warn("Ignoring message from unexpected origin:", event.origin);
    return;
  }
  const { data } = event;
  if (data.type === "editField") {
    return data;
  }
};

export const utilities = {
  formatDate,
  addPropertyIfNotEmpty,
  generateGUID,
  handleMessage,
};

const colorMap: { [key: string]: string } = {};

const generateRandomColor = (): string => {
  const colors = ["#F1A81E", "#DC3545", "#3C4F1E", "#C96421"];
  return colors[Math.floor(Math.random() * colors.length)];
};

export const setChipColors = (dataList: any[], typeKey: string) => {
  return dataList.map(item => {
    const type = item[typeKey];
    if (!colorMap[type]) {
      colorMap[type] = generateRandomColor();
    }
    return {
      ...item,
      chipColor: colorMap[type]
    };
  });
};

export const handleApiResponse = (response: any, showSnackbar: (title: string, message: string | null, severity: AlertColor) => void, content?: any): any => 
  {
  if (!response) {
    console.error("Response is undefined");
    return null;
  }
  
  const { data, status } = response;
  console.log(data);
  if (status === 200 && data.httpStatusCode === 200) {
    if(content)
    {
      showSnackbar(content.title, content.message, "success");
    }
    return data.details;
  } else {
    showSnackbar(
      data.details, 
      data.message || 'An error occurred', 
      data.httpStatusCode === 400 ? 'warning' : 'error'
    );
    return null;
  }
};


