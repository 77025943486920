import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { termsAndConditionsHtml } from './terms-and-conditions';
import { privacyPolicyHtml } from './privacy-policy';

export default function Footer({ open }) {
  const theme = useTheme();
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);

  return (
    <Box
      sx={{
        position: "sticky",
        paddingTop: 0.75,
        paddingBottom: 0.75,
        backgroundColor: theme.palette.primary.dark,
        textAlign: "center",
        minHeight: "30px",
        marginLeft: open ? "240px" : "0px",
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
        <Typography color="white" fontSize="0.75rem">
          Copyright © Farmers Friend {new Date().getFullYear()}
        </Typography>
        <Typography color="white" fontSize="0.75rem">
          /
        </Typography>
        <Typography 
          color="white" 
          fontSize="0.75rem"
          sx={{ 
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline' }
          }}
          onClick={() => setOpenPrivacy(true)}
        >
          Privacy Policy
        </Typography>
        <Typography color="white" fontSize="0.75rem">
          /
        </Typography>
        <Typography 
          color="white" 
          fontSize="0.75rem"
          sx={{ 
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline' }
          }}
          onClick={() => setOpenTerms(true)}
        >
          Terms and Conditions
        </Typography>
      </Box>

      <Dialog 
        open={openPrivacy} 
        onClose={() => setOpenPrivacy(false)}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            maxHeight: '80vh',
          },
        }}
      >
        <DialogTitle>Privacy Policy</DialogTitle>
        <DialogContent dividers>
          <div dangerouslySetInnerHTML={{ __html: privacyPolicyHtml }} />
        </DialogContent>
      </Dialog>

      <Dialog 
        open={openTerms} 
        onClose={() => setOpenTerms(false)}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            maxHeight: '80vh',
          },
        }}
      >
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent dividers>
          <div dangerouslySetInnerHTML={{ __html: termsAndConditionsHtml }} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}