import React from "react";
import { Chip } from "@mui/material";

interface DynamicChipProps {
  name: string;
  types: any[];
}

const DynamicChip: React.FC<DynamicChipProps> = ({ name, types }) => {
  const colors = ["#DC3545", "#3C4F1E", "#2E8B57", "#C96421", "#F1A81E"];

  const getColor = (index: number): string => {
    return colors[index % colors.length];
  };

  const type = types.find((t) => t.name === name);
  const backgroundColor = getColor(type?.noteTypeId);

  return (
    <Chip
      label={name}
      style={{ backgroundColor, color: "#ffffff", width: "110px" }}
    />
  );
};

export default DynamicChip;
