export const termsAndConditionsHtml = `<!DOCTYPE html> 
<html>
	<head>
		<title>Terms and Conditions</title>
		<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    </head>
    <body>
        <div>
            <p><strong>TERMS AND CONDITIONS OF USE OF THE FARMERS FRIEND APPLICATION</strong></p>
            <p><strong>1. DEFINITIONS</strong></p>
            <p>1.1. Unless the context indicates otherwise, the words and expressions set out below shall have the meanings assigned to them hereunder and cognate expressions shall have a corresponding meaning, namely:</p>
            <p>&nbsp;&nbsp;1.1.1. &#8220;<strong>Confidential Information</strong>&#8221; means all information, in any format or material embodiment including information which aUser of the AGNIFY App uploads and/or stores on the AGNIFY App, or which the User provides to AGNIFY for purposes of utilising the AGNIFY App, and which such User has an interest in keeping confidential and which is disclosed by such User to AGNIFY prior to,during the existence of or otherwise in connection with the User&#8217;s engagement with AGNIFY;</p>
            <p>&nbsp;&nbsp;1.1.2. &#8220;<strong>AGNIFY</strong>&#8221; means Agnify Proprietary Limited with registration number: 2024/415192/07;</p>
            <p>&nbsp;&nbsp;1.1.3. &#8220;<strong>AGNIFY App</strong>&#8221; means the digital mobile and website application operated and managed by AGNIFY known as the &#8216;Farmers Friend App&#8217;;</p>
            <p>&nbsp;&nbsp;1.1.4. &#8220;<strong>AGNIFY App Product Suites</strong>&#8221; means the various modules of the AGNIFY App in terms of which the functionalities of the Package are made available for use by the User;</p>
            <p>&nbsp;&nbsp;1.1.5. &#8220;<strong>Package</strong>&#8221; means services rendered by AGNIFY to the User via the AGNIFY App Product Suites, as selected by the User during Registration, including farm and field mapping, monitoring of farming activities, weather reporting, tracking soil moisture and normalised difference vegetation index, crop monitoring, contract management, organisation information storage, input procurement, keeping and updating an asset register, seasonal budgeting, harvest and yield reporting, verification, data sharing proxy and any further services confirmed by AGNIFY in writing on the AGNIFY App from time to time;</p>
            <p>&nbsp;&nbsp;1.1.6. &#8220;<strong>Personal Information</strong>&#8221; shall have the meaning ascribed thereto in terms of the POPI Policy;</p>
            <p>&nbsp;&nbsp;1.1.7. &#8220;<strong>POPI</strong>&#8221; shall have the meaning ascribed thereto in terms of the POPI Policy;</p>
            <p>&nbsp;&nbsp;1.1.8. &#8220;<strong>POPI Policy</strong>&#8221; means the POPI policy, privacy policy and PAIA manual of AGNIFY;</p>
            <p>&nbsp;&nbsp;1.1.9. &#8220;<strong>Products</strong>&#8221; means the goods of Suppliers as displayed on the AGNIFY App;</p>
            <p>&nbsp;&nbsp;1.1.10. &#8220;<strong>Registration</strong>&#8221; means the process of the User completing the online registration process for the Subscription contained on the AGNIFY App;</p>
            <p>&nbsp;&nbsp;1.1.11. &#8220;<strong>Sensitive Information</strong>&#8221; shall include both Personal Information and Confidential Information;</p>
            <p>&nbsp;&nbsp;1.1.12. &#8220;<strong>Subscription</strong>&#8221; means a User whose Registration for a specific AGNIFY App Product Suite has been approved by AGNIFY;</p>
            <p>&nbsp;&nbsp;1.1.13. &#8220;<strong>Subscription Fee</strong>&#8221; means the amount payable by the User for the Subscription Termas indicated by AGNIFY to the User during the Registration, or as otherwise displayed on the AGNIFY App from time to time;</p>
            <p>&nbsp;&nbsp;1.1.14. &#8220;<strong>Subscription Term</strong>&#8221; means the period of Subscription selected by the User during Registration;</p>
            <p>&nbsp;&nbsp;1.1.15. &#8220;<strong>Suppliers</strong>&#8221; means the entities and/or individuals who have their Products displayed on the AGNIFY App, which Products are available for sale;</p>
            <p>&nbsp;&nbsp;1.1.16. &#8220;<strong>Terms and Conditions</strong>&#8221; means the terms and conditions as detailed below; and</p>
            <p>&nbsp;&nbsp;1.1.17. &#8220;<strong>User</strong>&#8221; means any user who accesses, uses and/or subscribes to the AGNIFY App and &#8220;Users&#8221; shall have a similar meaning as the context requires.</p>
            <p><strong>2. PURPOSE AND OBJECTIVES</strong></p>
            <p>The purpose and objective of these Terms and Conditions is to regulate the relationship between the  User and AGNIFY regarding the User&#8217;s use, access and/or Subscription to the AGNIFY App.</p>
            <p><strong>3. BINDING NATURE OF TERMS AND CONDITIONS</strong></p>
            <p>The User&#8217;s acceptance of the Terms and Conditions will be indicated by the User accessing, using and/or subscribing to the AGNIFY App, which access, use and/or Subscription will render these Terms and Conditions binding upon the User.</p>
            <p><strong>4. TERMS AND CONDITIONS</strong></p>
            <p>These Terms and Conditions as amended by AGNIFY from time to time by AGNIFY, set out the basis on which the User may access and use the AGNIFY App and regulates the relationship between the User and AGNIFY in relation to the AGNIFY App.</p>
            <p><strong>5. SUBSCRIPTION</strong></p>
            <P>5.1. <i>Billing:</i> As consideration for the Package, the User agreesto pay AGNIFY the Subscription Fee in respect ofthe Subscription Term.</p>
            <P>5.2. <i>Review:</i></P>
            <P>&nbsp;&nbsp;5.2.1. AGNIFY shall have the right to amend and increase the Subscription Fee, without notice to the User, by publishing amended and increased Subscription Fees on the AGNIFY App, in its sole and absolute discretion.</p>
            <p>&nbsp;&nbsp;5.2.2. The User acknowledges, agrees and consents to such amendment and/or increase of the Subscription Fee by AGNIFY in terms of <strong>paragraph 5.2.1, by virtue of the Subscription.</strong></p>
            <p>5.3. <i>General payment terms:</i></p>
            <p>&nbsp;&nbsp;5.3.1. All payments to be made pursuant to these Terms and Conditions shall be made in Rands in immediately available funds, by debit order and/or via a payment gateway and shall be deemed to be made when the payments are received by AGNIFY and shall be accounted for accordingly.</p>
            <p>&nbsp;&nbsp;5.3.2. All payments to be made pursuant to these Terms and Conditions shall, save as expressly provided for in these Terms and Conditions, be made without the cost of transfer of funds and free of exchange or other deduction and without the right of deferment or avoidance by virtue of any counter claim or set-off.</p>
            <p>&nbsp;&nbsp;5.3.3. If any payment to be made by the User pursuant to these Terms and Conditions falls due for payment on a day which is not a Business Day, then the User shall make such payment on the following Business Day.</p>
            <p><strong>6. GENERAL UTILISATION OF THE AGNIFY APP</strong></p>
            <p>6.1. <i>Relationship:</i> If the User is under the age of 18 (eighteen), the User must obtain the User&#8217;s parents&#8217; or legal guardians&#8217; advance authorisation, permission, and consent to be bound by these Terms and Conditions.</p>
            <p>6.2. <i>Confidentiality of Communications:</i></p>
            <p>&nbsp;&nbsp;6.2.1. Any communication sent through the AGNIFY App is not confidential and is not protected by privilege.</p>
            <p>&nbsp;&nbsp;6.2.2. The User acknowledges that AGNIFY is unable to preserve the confidentiality of communications sent, stored and/or uploaded on or through the AGNIFY App.</p>
            <p>6.3. <i>Content of the AGNIFY App:</i></p>
            <p>&nbsp;&nbsp;6.3.1. AGNIFY reserves the right to make improvements, to change or to discontinue any aspect or feature of the AGNIFY App from time to time, as well as any information and/or content on the AGNIFY App, and to change and amend the services, prices and rates quoted on the AGNIFY App for the Products without notice to the User.</p>
            <p>&nbsp;&nbsp;6.3.2. AGNIFY reserves the right to make use of the services of third parties to provide information on the AGNIFY App.</p>
            <p>6.4. <i>Reliance on Information Posted:</i> </p>
            <p>&nbsp;&nbsp;6.4.1. Although AGNIFY takes all reasonable measures to ensure that the materials and information on the AGNIFY App are accurate and complete, such materials and information are dependent on the information and materials provided by Suppliers and cannot be warranted by AGNIFY.</p>
            <p>&nbsp;&nbsp;6.4.2. No representation or warranty, express or otherwise implied, is made by AGNIFY in respect to the accuracy or completeness of materials and information on the AGNIFY App.</p>
            <p>6.5. <i>Links:</i></p>
            <p>&nbsp;&nbsp;6.5.1. Links from the AGNIFY App to any other website are provided for convenience only, and do not imply affiliation with or endorsement by AGNIFY of the other website.</p>
            <p>&nbsp;&nbsp;6.5.2. Any dealings that the User may have with linked websites, including advertisements found onthe AGNIFY App, are solely between the Userand the third-party website and the User waives any claims it might have against AGNIFY in respect thereof.</p>
            <p>&nbsp;&nbsp;6.5.3. AGNIFY does not assume any responsibility for the protection and privacy of any information which the User has provided while visiting such third-party websites. The User should exercise caution when accessing such third-party websites.</p>
            <p>&nbsp;&nbsp;6.5.4. The User may not create a link to the AGNIFY App from a third-party website or document without AGNIFY&#8217;s prior written consent.</p>
            <p>6.6. <i>Security of the AGNIFY App:</i></p>
            <p>&nbsp;&nbsp;6.6.1. The internet is not a secure medium. Nonetheless, AGNIFY is committed to ensuring that the User&#8217;s material and information is secure.</p>
            <p>&nbsp;&nbsp;6.6.2. AGNIFY has put in place reasonable physical, electronic and procedural safeguards to secure material and information against loss, misuse, damage or modification and unauthorized access or disclosure.</p>
            <p>&nbsp;&nbsp;6.6.3. In the event that third parties are used to provide related services involving material and information the User supplies through the AGNIFY App, AGNIFY requires such third parties to meet appropriate and reasonable security standards.</p>
            <p>&nbsp;&nbsp;6.6.4. Whilst AGNIFY has taken reasonable measures to ensure the integrity of the AGNIFY App and its contents, no warranty, whether express or implied, is given that any files,downloads or applications available via the AGNIFY App are free of viruses, or any other data or code which has the ability to corrupt, damage or affect the operation of the User&#8217;s system.</p>
            <p>&nbsp;&nbsp;6.6.5. AGNIFY does not warrant that the AGNIFY App or information or downloads shall be error-free or that they shall meet any particular criteria of performance or quality. AGNIFY expressly disclaims all implied warranties, including without limitation, warranties of merchantability, fitness for a particular purpose, non-infringement, compatibility, security and accuracy.</p>
            <p>&nbsp;&nbsp;6.6.6. To ensure the security and reliable operation of the AGNIFY App, AGNIFY hereby reserves theright to take whatever action it may deem necessary to preserve the security, integrity and reliability of its network and back-office applications.</p>
            <p>&nbsp;&nbsp;6.6.7. The User may not utilise the AGNIFY App in any manner which may compromise thesecurity of AGNIFY&#8217;s networks or tamper with the AGNIFY App in any manner whatsoever,which shall include without limitation, gaining or attempting to gain unauthorised access to the AGNIFY App, or delivering or attempting todeliver any unauthorised, damaging or malicious code to the AGNIFY App, all of which is expressly prohibited. Any person or entity which does so, or attempts to do so, shall beheld criminally liable. Furthermore, should AGNIFY suffer any damage or loss, civil damages shall be claimed by AGNIFY against that person or entity.</p>
            <p>&nbsp;&nbsp;6.6.8. If the User commits any of the offences detailed in Chapter 13 of Electronic Communications and Transactions Act, No. 25 of 2002, the User shall, notwithstanding criminal prosecution, beliable for all resulting liability, loss or damages suffered and/or incurred by AGNIFY as well asits affiliates and agents.</p>
            <p>6.7. <i>Limitation of Liability</i>:</p>
            <p>&nbsp;&nbsp;6.7.1. The User agrees that all information provided on the AGNIFY App is provided &#8220;as is&#8221; and that AGNIFY shall not be liable for any losses or damages that may arise from the User&#8217;s reliance on it, howsoever these may arise. AGNIFY shall not assume and the User indemnifies AGNIFY for any liability for erroneous Products, prices and rates quoted on the AGNIFY App.</p>
            <p>&nbsp;&nbsp;6.7.2. AGNIFY does not assume any responsibility for the content or operation of external websites that link to the AGNIFY App or which are linked from the AGNIFY App and does not accept anyliability, whatsoever occurring and howsoever arising, (and is hereby indemnified by the User)in respect of the content or operation of anysuch external websites.</p>
            <p>&nbsp;&nbsp;6.7.3. The transmission of information via the internet, including without limitation e-mail, is susceptible to monitoring and interception. The User bears all risk of transmitting information inthis manner. Under no circumstances shallAGNIFY be liable for any loss, harm, ordamage suffered by the User as a resultthereof. AGNIFY reserves the right to request independent verification of any information transmitted via e-mail and the User consents to such verification should AGNIFY deem it necessary.</p>
            <p>&nbsp;&nbsp;6.7.4. To the extent permissible by law, neither AGNIFY, its affiliates, shareholders, agents, consultants or employees shall be liable and the User indemnifies AGNIFY, its affiliates, shareholders, agents, consultants or employees for any damages whatsoever, including without limitation any direct, indirect, special, incidental, consequential or punitived amages, howsoever arising (whether in an action arising out of contract, statute, delict or otherwise) related to the User&#8217;s use of the AGNIFY App, the User&#8217;s reliance upon information and content contained on the AGNIFY App, the inability to access or use the content of the AGNIFY App or any functionality thereof, the unavailability, interruption and/or malfunctioning of the AGNIFY App, the information contained on the AGNIFY App, or of any linked , and/or as a direct or indirect result of the User&#8217;s failure to comply with any of the terms or any other requirements of this Terms and Conditions.</p>
            <p>&nbsp;&nbsp;6.7.5. The aggregate liability of AGNIFY, of whatsoever nature, will be limited to or will not exceed an amount equal to 12 (twelve) months of the Subscription Fee which the User pays for the AGNIFY App.</p>
            <p><strong>7. POPI CONSENTS</strong></p>
            <p>7.1. This <strong>clause 7 should be read with the POPI Policy of AGNIFY.</strong></p>
            <p>7.2. By utilising the AGNIFY App each User fully and unconditionally consents to:</p>
            <p>&nbsp;&nbsp;7.2.1. AGNIFY&#8217;s processing of Personal Information of the User in accordance with POPI and subject to the POPI Policy;</p>
            <p>&nbsp;&nbsp;7.2.2. AGNIFY&#8217;s processing and/or storing of any Sensitive Information received from such User as may be required for AGNIFY to fulfil its functions and duties in terms of its engagementwith the User;</p>
            <p>&nbsp;&nbsp;7.2.3. AGNIFY providing the Sensitive Information to third party financiers as may be necessary for AGNIFY to perform its functions and duties interms of its engagement with the User;</p>
            <p>&nbsp;&nbsp;7.2.4. the use of any Sensitive Information by AGNIFY&#8217;s directors, employees, consultants, contractors, representatives, agents and advisers to the extent strictly necessary for the purpose of AGNIFY fulfilling its functions interms of its engagement with the User or for obtaining professional advice; and</p>
            <p>&nbsp;&nbsp;7.2.5. AGNIFY processing Personal Information and/or Sensitive Information for purposes of algorithms and/or the creation of reports and/or analytics, in such a manner that a third party will not be able to identify the specific User towhich the Personal Information and/or Sensitive Information relates.</p>
            <p>7.3. In utilising the AGNIFY App the User acknowledges and agrees that:</p>
            <p>&nbsp;&nbsp;7.3.1. neither AGNIFY nor any affiliate, officers, directors, partners, employees, consultants and/or contractors shall be held liable for anyloss and/or damage from whatsoever cause and howsoever arising from the User&#8217;s uploading and/or storing of Sensitive Information on the Application nor from any data breaches which may occur in relation tothe AGNIFY App; and</p>
            <p>&nbsp;&nbsp;7.3.2. they shall not have any claim against AGNIFY for any damage, harm and/or loss from whatsoever cause and howsoever arising, other than gross negligence on the part of AGNIFY, as a result of AGNIFY accessing, processing and/or storing the Sensitive Information.</p>
            <p><strong>8. PRIVACY POLICY FOR THE AGNIFY APP</strong></p>
            <p>This privacy policy for the AGNIFY App (&#8220;<strong>Privacy Policy</strong>&#8221;), as amended from time to time by AGNIFY, is subject to the terms and conditions of the POPI Policy of AGNIFY. This Privacy Policy sets out the basis on which AGNIFY may use and protect material and information that AGNIFY obtains from the User when using the AGNIFY App.</p>
            <p>8.1. <i>Collection of information:</i></p>
            <p>&nbsp;&nbsp;8.1.1. By the User&#8217;s use of the AGNIFY App, the User consents that AGNIFY may monitor the User&#8217;s visits to the AGNIFY App and retain information about the User&#8217;s user traffic (including but no tlimited to browser-generated information and any Sensitive Information provided to AGNIFY when the User are communicating with AGNIFY through the AGNIFY App).</p>
            <p>&nbsp;&nbsp;8.1.2. By the User&#8217;s use of the AGNIFY App, the User consents that AGNIFY may collect the following information:</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;8.1.2.1. <i>General Material and Information:</i></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.1.2.1.1. General material and information constitute material and information provided to AGNIFY on the AGNIFY App for general purposes when registering on the AGNIFY App, including but not limited to information provided to AGNIFY when submitting material or requesting further information.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.1.2.1.2. Material and information supplied for general purposes may be used for marketing purposes.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;8.1.2.2. <i>Specific Material and Information:</i></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.1.2.2.1. Specific material and information constitute material and information provided to AGNIFY on the AGNIFY App for purposes of monitoring the information on behalf of third-party financiers, insofar as the User has consented to the aforesaid.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.1.2.2.2. Material and information provided for specific purposes may not be used for marketing purposes.</p>
            <p>8.2. <i>Use of the User&#8217;s material and information:</i> The material and information provided to AGNIFY through the AGNIFY App will be used:</p>
            <p>&nbsp;&nbsp;8.2.1. to provide any of AGNIFY&#8217;s services or the Package to the User;</p>
            <p>&nbsp;&nbsp;8.2.2. to provide any of AGNIFY&#8217;s services to any third-party financier that might finance any transaction the User concludes via the AGNIFY App, insofar as applicable and consented to by the User;</p>
            <p>&nbsp;&nbsp;8.2.3. to provide any of AGNIFY&#8217;s services to Suppliers of Products purchased by the User;</p>
            <p>&nbsp;&nbsp;8.2.4. improve the AGNIFY App and AGNIFY&#8217;s services;</p>
            <p>&nbsp;&nbsp;8.2.5. for algorithms and/or the creation of reports and/or analytics;</p>
            <p>&nbsp;&nbsp;8.2.6. for internal business administration including fulfilling any legal requirements;</p>
            <p>&nbsp;&nbsp;8.2.7. to provide the User with information and services that the User requests from AGNIFY or where the User has consented to be contacted for such purposes providing the User with information which AGNIFY feel may be of interest to the User; and</p>
            <p>&nbsp;&nbsp;8.2.8. recruitment or selection procedures.</p>
            <p>8.3. <i>Disclosure of the User&#8217;s material and information:</i></p>
            <p>&nbsp;&nbsp;8.3.1. Any material and information provided to AGNIFY through the AGNIFY App will not be disclosed to parties unrelated to AGNIFY,except as otherwise provided for in this Privacy Policy.</p>
            <p>&nbsp;&nbsp;8.3.2. The circumstances in which AGNIFY may disclose material and information provided by the User through the AGNIFY App to partiesunrelated to AGNIFY are set out hereunder:</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;8.3.2.1. if the User have consented for AGNIFY todo so;</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;8.3.2.2. if otherwise set out in the Privacy Policy;</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;8.3.2.3. if AGNIFY is under a legal, regulatory or professional obligation to do so;</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;8.3.2.4. in the instance that AGNIFY merge, re-organise or transfer all or part of itsbusiness in which case the User&#8217;s materialinformation will be disclosed to potential oractual successors of the business; and</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;8.3.2.5. if AGNIFY use third-party service providers to provide services that involve dataprocessing (where AGNIFY use serviceproviders, AGNIFY will ensure theappropriate safeguards are in place toprotect the User&#8217;s information).</p>
            <p>8.4. <i>The period of retention of the User&#8217;s material and information:</i></p>
            <p>&nbsp;&nbsp;8.4.1. The time periods for which AGNIFY is required to retain the User&#8217;s information may varyaccording to the use and purpose attached to the information.</p>
            <p>&nbsp;&nbsp;8.4.2. Unless there is a specific legal requirement requiring AGNIFY to keep the User&#8217;s information, however, AGNIFY will not keep it for longer than necessary for the purpose for which the information was collected or for which it is to be processed.</p>
            <p>8.5. <i>Use of IP Addresses:</i> AGNIFY may make use of software and technology to collect information about the use of the AGNIFY App and to distinguish the User from other users of AGNIFY&#8217;s AGNIFY App in order to improve the User&#8217;s experience when the User browse the AGNIFY App.</p>
            <p>8.6. <i>The User&#8217;s Rights:</i> The User are entitled to request details about the information AGNIFY holds about the User.</p>
            <p>8.7. <i>AGNIFY&#8217;s Marketing Materials:</i> If at any time if the User would prefer to receive orstop receiving marketing materials from AGNIFY,this must be communicated to AGNIFY in writing.</p>
            <p>8.8. <i>Status of this Privacy Policy:</i> This Privacy Policy forms part of AGNIFY&#8217;s Terms and Conditions.</p>
            <p><strong>9. COPYRIGHT</strong></p>
            <p>9.1. All the copyright content appearing on the AGNIFY App belongs to AGNIFY, unless otherwise stated.All rights are reserved.</p>
            <p>9.2. The User hereby agrees that the User shall not, nor through a third party:</p>
            <p>&nbsp;&nbsp;9.2.1. copy (other than for backup, archival or disaster recovery purposes), reproduce, translate, adapt, vary, modify, lease, licence, sub-licence, encumber or in any other way deal with any part of the AGNIFY App for any reason and in any manner, unless it is consistent with the intent and purpose of these Terms and Conditions;</p>
            <p>&nbsp;&nbsp;9.2.2. decompile, disassemble or reverse engineer any portion of the AGNIFY App;</p>
            <p>&nbsp;&nbsp;9.2.3. write and/or develop any derivative of the AGNIFY App or any other software program based on the AGNIFY App;</p>
            <p>&nbsp;&nbsp;9.2.4. modify or enhance the AGNIFY App. In the event of the User effecting any modifications or enhancements to the AGNIFY App in breach ofthis clause, such modifications and enhancements shall be the property of AGNIFY;</p>
            <p>&nbsp;&nbsp;9.2.5. without AGNIFY&#8217;s prior written consent, provide, disclose, divulge or make available toor permit the use of or give access to the AGNIFY App by persons other than the User;</p>
            <p>&nbsp;&nbsp;9.2.6. remove any identification, trademark, copyright or other notices from the AGNIFY App; and</p>
            <p>&nbsp;&nbsp;9.2.7. post or transmit, by means of reviews, comments, suggestions, ideas, questions orother information through the AGNIFY App,any content which is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, sexually-explicit, profane or hateful, or racially, ethnically or otherwise objectionable content of any kind.</p>
            <p>9.3. The User may print or download any material or information on the AGNIFY App and retain a copy of it for the User&#8217;s personal use only. Copies of materials and information from this site may be distributed to third parties for their personal use provided that:</p>
            <p>&nbsp;&nbsp;9.3.1. the User does so free of charge;</p>
            <p>&nbsp;&nbsp;9.3.2. the content of the material and information and the copyright notices remain intact;</p>
            <p>&nbsp;&nbsp;9.3.3. the User agrees that the communication of the materials will not be misleading, inaccurate,used for any illegal purpose or in a manner that would bring AGNIFY into disrepute; and</p>
            <p>&nbsp;&nbsp;9.3.4. the User informs the third party that these Terms and Conditions apply, and the third party agrees to comply with these Terms and Conditions.</p>
            <p>9.4. The User will request written consent from AGNIFY to make any other use of AGNIFY&#8217;s material and information, including, in particular, its incorporation into any other electronic or hard copy document.</p>
            <p>9.5. Copyright in some materials available on the AGNIFY App may belong to third parties and such materials have been produced on the AGNIFY App with the permission of the third-party copyright owners. Unless otherwise specified, the User may not copy or distribute or otherwise make available or communicate to the public any part of third-party document or use them for any commercial purpose.</p>
            <p><strong>10. INTELLECTUAL PROPERTY RIGHTS</strong></p>
            <p>10.1. For the purpose of this clause, &#8220;<strong>Intellectual property rights</strong>&#8221; means all and any of the rights inand to intellectual property of any nature whatsoever owned and/or controlled directly or under licence by AGNIFY, now or in the future, including without limitation, AGNIFY&#8217;s rights, title and interest in and to all technology, source code/s,trade secrets, logos, systems, methods, trademarks, trade names, styles, insignia, designs, patents and copyright, and all similar proprietary rights which may subsist in any part of the world, whether registered or not.</p>
            <p>10.2. All Intellectual Property rights in all content, trademarks, software, data, material, including logos, databases, text, graphics, icons, hyperlinks, Confidential Information, designs, agreements, and multimedia works, published on or via the AGNIFY App (&#8220;<strong>Proprietary Material</strong>&#8221;), are the property of,or are licensed to, AGNIFY and as such are protected from infringement by local and international legislation and treaties.</p>
            <p>10.3. By submitting reviews, comments and/or any other content (other than the User&#8217;s Sensitive Information) to AGNIFY for posting on the AGNIFY App, the User automatically grants AGNIFY and itsaffiliates a non-exclusive, royalty-free, perpetual,irrevocable right and licence to use, reproduce,publish, translate, sub-license, copy and distributesuch content in whole or in part worldwide, and to incorporate it in other works in any form, media, ort echnology now known or hereinafter developed,for the full term of any copyright that may exist insuch content. Subject to this licence, the User retains any and all rights that may exist in such content.</p>
            <p>10.4. All rights not expressly granted are reserved and no right, title or interest in any Proprietary Material or information contained in the AGNIFY App is granted to the User.</p>
            <p>10.5. The User acknowledges that the User have no right, title or interest in any material posted and contained on the AGNIFY App.</p>
            <p><strong>11. BREACH</strong></p>
            <p>11.1. AGNIFY is entitled without notice, in addition to any other remedy available to it at law or under these Terms and Conditions, including obtaining an interdict, to cancel these Terms and Conditions, limit or deny the User&#8217;s use of the AGNIFY App and AGNIFY&#8217;s services, or to claim specific performance of any obligation whether or not the due date for performance has arrived, in either event without prejudice to AGNIFY&#8217;s right to claim damages, should the User:</p>
            <p>&nbsp;&nbsp;11.1.1. breach any of these Terms and Conditions;</p>
            <p>&nbsp;&nbsp;11.1.2. in the sole discretion of AGNIFY, use the AGNIFY App in an unauthorised manner; or</p>
            <p>&nbsp;&nbsp;11.1.3. infringe any statute, regulation, ordinance or law.</p>
            <p>11.2. Breach of these Terms and Conditions entitles AGNIFY to take legal action without prior notice to the User and the User agrees to reimburse the costs associated with such legal action to AGNIFY on an attorney and own client scale.</p>
            <p><strong>12. COMPLIANCE WITH LAWS</strong></p>
            <p>By the User&#8217;s access, use and/or Subscription of the AGNIFY App, the User warrants that the User complies with all applicable laws, statutes, ordinances and regulations pertaining to the User&#8217;s use of, access toand Subscription to the AGNIFY App.</p>
            <p><strong>13. GOVERNING LAW</strong></p>
            <p>Use of the AGNIFY App and the legal notices contained on the AGNIFY App shall be governed by and interpreted in accordance with the law of the Republic of South Africa and the User consents to the exclusive jurisdiction of the courts of the Republic of South Africa.</p>
            <p><strong>14. AMENDMENTS OF TERMS AND CONDITIONS</strong></p>
            <p>AGNIFY reserves the right, in its sole discretion to amend and/or replace any of, or the whole of these Terms and Conditions from time to time without   to the User. Such amendments shall supersede and replace all previous Terms and Conditions and shall be made available on the AGNIFYApp. Each time the User accesses the AGNIFY App,the User shall be deemed to have consented, by such access, to the Terms and Conditions, as amended and/or replaced by AGNIFY from time to time. If the User is not satisfied with such amended Terms and Conditions, the User should refrain from using the AGNIFY App.</p>
            <p><strong>15. GENERAL</strong></p>
            <p>15.1. The User shall not be entitled to cede the User&#8217;s rights, assign the User&#8217;s rights or delegate theUser&#8217;s obligations in terms of these Terms and Conditions to any third party without the prior written consent of AGNIFY.</p>
            <p>15.2. The User shall not have any claim or right of action arising from any undertaking, representation orwarranty not included in this Terms and Conditions.</p>
            <p>15.3. No failure by AGNIFY to enforce any provision of this Terms and Conditions shall constitute a waiverof such provision or affect in any way AGNIFY&#8217;sright to require performance of any such provision at any time in the future, nor shall the waiver of any subsequent breach nullify the effectiveness of the provision itself.</p>
            <p>15.4. It is agreed that each clause and sub-clause of this Terms and Conditions is severable, the one from the other, and if any clause or sub-clause is foundto be defective or unenforceable for any reason by any competent court, then the remaining clauses and sub-clauses shall continue to be of full force and effect.</p>
            <p>16. <strong>INTERPRETATION</strong></p>
            <p>16.1. In this Terms and Conditions, unless the context requires otherwise:</p>
            <p>&nbsp;&nbsp;16.1.1. words importing any one gender shall include the other gender;</p>
            <p>&nbsp;&nbsp;16.1.2. the singular shall include the plural and <i>vice versa</i>; and</p>
            <p>&nbsp;&nbsp;16.1.3. a reference to natural persons shall include created entities (corporate or unincorporated)and <i>vice versa</i>.</p>
            <p>16.2. In this Terms and Conditions, the headings have been inserted for convenience only and shall not be used to assist or affect its interpretation.</p>
            <p>16.3. Any reference in this Terms and Conditions to an enactment is to that enactment as amended or re-enacted from time to time.</p>
            <p>16.4. Words and/or expressions defined in any clause in the body of this Terms and Conditions shall, unless the application of such words and/or expressions is specifically limited to that clause, bear the meaning so assigned to it throughout this Terms and Conditions.</p>
            <p>16.5. The <i>contra proferentem</i> rule shall not apply and accordingly, none of the provisions hereof shall be construed against or interpreted to the disadvantage of AGNIFY.</p>
            <p>16.6. The <i>eiusdem generis</i> rule shall not apply and accordingly, whenever a provision is followed by the word &#8220;including&#8221; followed by specific examples, such examples shall not be construed to limit the ambit of the provision concerned.</p>
            <p>16.7. A reference to any statutory body or court shall be construed as a reference to that statutory body orcourt as substituted from time to time thereafter by successor statutory bodies or courts, as the case may be</p>
        </div>
    </body>
</html>`;