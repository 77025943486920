import React from "react";
import {
  MenuItem,
  FormControl,
  SelectChangeEvent,
  CircularProgress,
  Typography,
} from "@mui/material";
import TextBox from "./textBox";

interface DropdownItem {
  value: string | number;
  label: string;
}

interface DropdownProps {
  label: string;
  name: string;
  value: string | number;
  items: DropdownItem[];
  onChange: (event: SelectChangeEvent<string | number>) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  loading?: boolean;
  sx?: any;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  name,
  value,
  items,
  onChange,
  error = false,
  helperText = "",
  disabled = false,
  loading = false,
  sx,
  ...props
}) => {
  return (
    <FormControl fullWidth error={error} sx={sx}>
      <TextBox
        select
        id={`${name}-select`}
        value={value}
        label={label}
        onChange={onChange}
        disabled={disabled}
        {...props}
      >
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={24} />
          </MenuItem>
        ) : items.length === 0 ? (
          <MenuItem disabled>No Options Available</MenuItem>
        ) : (
          items
            .filter((item) => item.value !== "")
            .map((item) => (
              <MenuItem key={item.value} value={item.value}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {item.label}
                </div>
              </MenuItem>
            ))
        )}
      </TextBox>
      {error && helperText && (
        <Typography color="error">{helperText}</Typography>
      )}
    </FormControl>
  );
};

export default Dropdown;
