import React from "react";
import {
  FormControl,
  Checkbox,
  TextField,
  Autocomplete,
} from "@mui/material";

const MultiSelect = ({
  label,
  value,
  onChange,
  options,
  error,
  helperText,
  fieldId,
  sx,
}) => {
  // Convert selected values into option objects
  const selectedOptions = options.filter((option) => value.includes(option.id));

  return (
    <FormControl
      fullWidth
      margin="dense"
      error={error}
      sx={{ mb: 1, ...sx }}
      variant="outlined"
      size="small"
    >
      <Autocomplete
        multiple
        id={fieldId}
        options={options}
        getOptionLabel={(option) => option.label}
        value={selectedOptions}
        onChange={(event, newValue) => {
          onChange(newValue.map((option) => option.id));
        }}
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              checked={selected}
              style={{ marginRight: 8 }}
            />
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            error={error}
            helperText={helperText}
            size="small"
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </FormControl>
  );
};

export default MultiSelect;
