import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import GenericConfirmDialog from "../organisms/genericConfirmDialog";

import theme from "../../theme";

const ProductTable = ({ products, handleRequestDeleteProduct, handleEditProduct }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const handleOpenConfirmDialog = (index) => {
    setDeleteIndex(index);
    setConfirmOpen(true);
  };

  const handleConfirmDelete = () => {
    if (deleteIndex !== null) {
      handleRequestDeleteProduct(deleteIndex);
    }
    setConfirmOpen(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="product table">
          <TableHead>
            <TableRow>
              <TableCell 
                align="center"
                sx={{ 
                  backgroundColor: theme.palette.secondary.main,
                  color: theme.palette.primary.contrastText,
                  fontSize: '0.75rem',
                }}
              >
                Product
              </TableCell>
              <TableCell 
                align="center"
                sx={{ 
                  backgroundColor: theme.palette.secondary.main,
                  color: theme.palette.primary.contrastText,
                  fontSize: '0.75rem',
                }}
              >
                Dosage (Qty)
              </TableCell>
              <TableCell 
                align="center"
                sx={{ 
                  backgroundColor: theme.palette.secondary.main,
                  color: theme.palette.primary.contrastText,
                  fontSize: '0.75rem',
                }}
              >
                UOM
              </TableCell>
              <TableCell 
                align="center"
                sx={{ 
                  backgroundColor: theme.palette.secondary.main,
                  color: theme.palette.primary.contrastText,
                  fontSize: '0.75rem',
                }}
              >
                Cost per ha
              </TableCell>
              <TableCell 
                align="center"
                sx={{ 
                  backgroundColor: theme.palette.secondary.main,
                  color: theme.palette.primary.contrastText,
                  fontSize: '0.75rem',
                }}
              >
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {product.Product}
                </TableCell>
                <TableCell align="right">{product.Quantity}</TableCell>
                <TableCell align="right">{product.UOM}</TableCell>
                <TableCell align="right">{product.CostPerHectare}</TableCell>
                <TableCell align="right">
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleEditProduct(index)}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleOpenConfirmDialog(index)}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <GenericConfirmDialog
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        content="Are you sure you want to delete this product?"
      />
    </>
  );
};

export default ProductTable;