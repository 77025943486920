import React, { useState, useRef } from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Divider,
  AlertColor,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { fetchData, useFetchData } from "../../hooks/useFetchData";
import {
  getOrganizationFarms,
} from "../../services/api-ffm-service";
import { 
  getLinkedFields 
} 
  from "../../services/api-gs-service";
import { Farm } from "../../models/farm.interface";
import CustomSnackbar from "../atom/CustomSnackbar";
import { useConfig } from "../../context/ConfigContextTest";


interface FarmFieldDisplayProps {
  handleSetFieldId: (fieldId: string) => void;
}

const FarmFieldDisplay: React.FC<FarmFieldDisplayProps> = ({ handleSetFieldId }) => {  
  const [farms, setFarms] = useState<Farm[]>([]);
  const [linkedFields, setLinkedFields] = useState<{ [key: number]: any[] }>({});
  const [expandedFarms, setExpandedFarms] = useState<{ [key: number]: boolean }>({});
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { selectedOrganization } = useConfig();
  
  const snackbarRef = useRef<{ handleOpen: (title: string, msg: string | null, sev: AlertColor) => void }>(null);

  useFetchData(getOrganizationFarms, setFarms, undefined, [
    selectedOrganization?.organizationId ?? 0,
  ]);

  const collapseFarmsButCurrent = (farmId: number) => {
    setExpandedFarms((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        if (Number(key) !== farmId) {
          newState[Number(key)] = false;
        }
      });
      return newState;
    });
  }   

  const toggleFarm = (farmId: number, farmIdentifier: string) => {
    if (!expandedFarms[farmId]) {
      collapseFarmsButCurrent(farmId);
    }

    setExpandedFarms((prevState) => ({
      ...prevState,
      [farmId]: !prevState[farmId],
    }));

    if (!expandedFarms[farmId]) {
      fetchData(
        getLinkedFields,
        async (data) => {
          const transformedData = await Promise.all(
            data.map(async (fieldData) => {
              const area = fieldData.area;
              const isIrrigated = fieldData.irrDry === "Irrigated";
              const fieldIconUrl = `${process.env.REACT_APP_FFM_BASE_URL}/api/fieldicon?farmFieldLinkingIdentifier=${fieldData.farmFieldLinkIdentifier}`;
  
              return {
                ...fieldData,
                area,
                isIrrigated,
                fieldIconUrl,
              };
            })
          );
          setLinkedFields((prevState) => ({
            ...prevState,
            [farmId]: transformedData,
          }));
        },
        undefined,
        [farmIdentifier]
      );
    }
  };

  const handleLinkedFieldClick = (fieldId: string) => {
    handleSetFieldId(fieldId);
    setSelectedIndex(Number(fieldId));
  };

  const cardParent = document.getElementById('farm-field-display-wrapper');

  return (
    <div
      style={{ 
        position: "absolute",
        top: 104,
        height: "calc(100vh - 134px)",
        padding: 0, 
        width: cardParent?.offsetWidth, 
        overflowY: "scroll" 
      }}
    >
      <List>
        {selectedOrganization &&
          farms.map((farm) => (
            <div key={farm.farmId}>
              <ListItemButton
                onClick={() => toggleFarm(farm.farmId, farm.farmIdentifier)}
              >
                <ListItemText primary={farm.farm} />
                {expandedFarms[farm.farmId] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse
                in={expandedFarms[farm.farmId]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {linkedFields[farm.farmId]?.map((field, index) => (
                    <ListItemButton 
                      key={index} 
                      selected={selectedIndex === field.fieldId}
                      onClick={() => handleLinkedFieldClick(field.fieldId)}
                      style={{ paddingTop: 4, paddingBottom: 4 }}
                    >
                      <div style={{ width: 40, height: 40, marginRight: 8, borderRadius: 4, backgroundColor: "#3c4f1e" }}> 
                        <img 
                          src={field.fieldIconUrl} 
                          style={{ width: 40, height: 40, borderRadius: 4 }} 
                          alt=""
                        />  
                      </div>
                      <ListItemText 
                        primary={field.fieldName} 
                        secondary={`${field.area} ha ${field.isIrrigated ? "irrigated" : ""}`.trim()} 
                        style={{ marginTop: 2, marginBottom: 2 }} 
                      />
                    </ListItemButton>
                  ))}
                  <Divider />
                </List>
              </Collapse>
            </div>
          ))}
      </List>
      <CustomSnackbar ref={snackbarRef} />
    </div>
  );
}

export default FarmFieldDisplay;