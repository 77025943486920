import React, { createContext, useState, useContext, ReactNode } from 'react';

interface PopupContextType {
  showMappingPopup: boolean;
  setShowMappingPopup: (show: boolean) => void;
}

const PopupContext = createContext<PopupContextType | undefined>(undefined);

interface PopupProviderProps {
  children: ReactNode;
}

export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const [showMappingPopup, setShowMappingPopup] = useState(false);

  return (
    <PopupContext.Provider value={{ showMappingPopup, setShowMappingPopup }}>
      {children}
    </PopupContext.Provider>
  );
};

export const usePopup = () => {
  const context = useContext(PopupContext);
  if (context === undefined) {
    throw new Error('usePopup must be used within a PopupProvider');
  }
  return context;
};