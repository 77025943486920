import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

interface SearchableSelectProps {
  id?: string;
  label: string;
  value: any;
  onChange: (event: any, newValue: any) => void;
  options: Array<{ label: string; value: any; id: any }>;
  error?: boolean;
  helperText?: any;
  inputRef?: React.Ref<any>;
  sx?: any;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  id,
  label,
  value,
  onChange,
  options,
  error,
  helperText,
  inputRef,
  sx,
}) => {
  const selectedOption = options.find(option => option.value === value) || null;

  return (
    <Autocomplete
      id={id}
      value={selectedOption}
      onChange={(event, newValue) => {
        onChange(event, newValue ? newValue.value : value);
      }}
      options={options}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={error}
          helperText={helperText}
          inputRef={inputRef}
          size="small"
          margin="dense"
          sx={sx}
        />
      )}
    />
  );
};

export default SearchableSelect;
