import React from "react";
import { useState } from "react";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Typography,
} from "@mui/material";

const MultiSelectAll = ({ 
  setIdSelection,
  setLabelSelection,
  label,
  options,
  error,
  helperText,
  fieldId,
  sx,
  preselected,
}) => {

  const [selected, setSelected] = useState<string[]>(preselected);
  
  const isAllSelected = options.length > 0 && selected.length === options.length;
  
  // Find the labels for the selected values
  /*
  const selectedLabels = selected
    .map((selectedId) => {
      const selectedOption = options.find((option) => option.id === selectedId);
      return selectedOption ? selectedOption.label : "";
    })
    .filter((label) => label !== "");
  */
  const selectedLabelsAll = () => {
    if(selected.length === options.length) {
        return "All";
    } else {
      return selected
      .map((selectedId) => {
        const selectedOption = options.find((option) => option.id === selectedId);
        return selectedOption ? selectedOption.label : "";
      })
      .filter((label) => label !== "").join(", ");      }
  }

  return (
  
    <FormControl
      fullWidth
      margin="dense"
      error={error}
      sx={{ mb: 1, ...sx }}
      variant="outlined"
      size="small"
    >
      <InputLabel id={`multiple-select-label-${fieldId}`}>{label}</InputLabel>
      <Select
        labelId={`multiple-select-label-${fieldId}`}
        id={`multiple-select-${fieldId}`}
        multiple
        value={selected}
        onChange={(event: any) => {
          if (event.target.value[event.target.value.length - 1] === "All") {
            if(selected.length === options.length) {
              setSelected([]);
              setIdSelection([]);
              setLabelSelection([]);
            } else { 
              const allIdOptions: string[] = [];
              //const allLabelOptions: string[] = [];
              options.forEach(option => {
                allIdOptions.push(option.id);
                //allLabelOptions.push(option.label);
              });
              setSelected(allIdOptions);
              setIdSelection(allIdOptions);
              setLabelSelection(["All"]);
            }  
            return;
          }
          setSelected(event.target.value); // local in MultipleSelectAll
          setIdSelection(event.target.value); // pass selected values to parent element
          const allLabelOptions: string[] = [];
          options.forEach(option => {
            if(event.target.value.indexOf(option.id) > -1) {
              allLabelOptions.push(option.label);
            }  
          });  
          setLabelSelection(allLabelOptions);
        }}
        input={<OutlinedInput label={label} />}
        renderValue={() => selectedLabelsAll()} 
       >
        <MenuItem
          value="All"
        >
          <Checkbox
            checked={isAllSelected}
            indeterminate={
              selected.length > 0 && selected.length < options.length
            }
          />
          <ListItemText primary="Select All" />
        </MenuItem>  

        {Array.isArray(options) ? (
          options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox checked={selected.indexOf(option.id) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No Options Available</MenuItem>
        )}
      </Select>
      {helperText && (
        <Typography variant="body2" color="error">
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
};

export default MultiSelectAll;
