import React, { useState, useMemo, useCallback } from "react";
import { LineChart, ComposedChart, BarChart, Line, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer } from "recharts";
import { Checkbox, FormGroup, FormControlLabel, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

interface DataItem {
  parameter: string;
  dateTime: string;
  value: number;
}

interface ChartDataItem {
  date: string;
  [key: string]: string | number | null;
}

const formatDate = (date: string): string => {
  const dateUTC = new Date(date);
  return new Date(dateUTC.getTime() + 24 * 60 * 60 * 1000).toISOString().split("T")[0];
};

const getYearMonthDay = (dateTime: string): string => {
  const dateObj = new Date(dateTime);
  return dateObj.toLocaleDateString('en-ZA', { year: 'numeric', month: '2-digit', day: '2-digit' });
};

const WeatherLineChart: React.FC<{ data: DataItem[] }> = ({ data }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const uniqueParameters = useMemo(() => Array.from(new Set(data?.map((item) => item.parameter))), [data]);
  //uniqueParameters.unshift("Accumulated Precipitation (mm)");

  const defaultParameters = ["Soil Moisture at -5cm (m³/m³)", "Soil Temperature at -5cm (°C)"];

  const [selectedParameters, setSelectedParameters] = useState<string[]>(
    
    uniqueParameters.filter(param => defaultParameters.includes(param)) || defaultParameters
  );

  const handleCheckboxChange = useCallback((parameter: string) => {
    setSelectedParameters((prevSelectedParameters) => {
      if (prevSelectedParameters.includes(parameter)) {
        return prevSelectedParameters.filter((item) => item !== parameter);
      } else if (prevSelectedParameters.length === 0) {
        return [...prevSelectedParameters, parameter];
      } else if (prevSelectedParameters.length === 1) {
        return [...prevSelectedParameters, parameter];
      } else if (prevSelectedParameters.length === 2) {  
        prevSelectedParameters.pop();
        return [...prevSelectedParameters, parameter];
      }
      return prevSelectedParameters;
    });
  }, []);

  const chartData: ChartDataItem[] = useMemo(() => {
    
    //console.log(JSON.stringify(data));

    const filteredData = data?.filter((item) => selectedParameters.includes(item.parameter));
    const uniqueDates = Array.from(new Set(filteredData.map((item) => formatDate(item.dateTime))));

    return uniqueDates.map((date) => {
      //console.log(selectedParameters);
      const entry: ChartDataItem = { date };
      if(selectedParameters.length === 2 && (selectedParameters[1] === "Precipitation (mm)" || selectedParameters[1] === "Accumulated Precipitation (mm)")) {
        selectedParameters.reverse();
      }
    
      selectedParameters.forEach((parameter) => {
        const item = filteredData.find(
          (d) => formatDate(d.dateTime) === date && d.parameter === parameter
        );
        entry[parameter] = item ? item.value : null;
      });
      return entry;
    }).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [data, selectedParameters]);

  const [firstMetric, secondMetric] = selectedParameters;
  

  const renderYAxis = (metric: string, orientation: "left" | "right") => (
    <YAxis
      yAxisId={orientation}
      orientation={orientation}
      label={{
        value: metric,
        angle: -90,
        position: orientation === 'left' ? 'insideLeft' : 'insideRight',
        offset: orientation === 'left' ? 0 : 10,
        style: { textAnchor: 'middle', fontSize: isSmallScreen ? 'calc(8px*0.875)' : isMediumScreen ? 'calc(10px*0.875)' : '12px' },
      }}
      scale="auto"
      domain={['auto', 'auto']}
      tick={{ fontSize: isSmallScreen ? 'calc(8px*0.875)' : isMediumScreen ? 'calc(10px*0.875)' : '12px' }}
    />
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let param: string = "";
      let unit: any = {} 
      let paramString: string = "";
      let paramArr: any[] = [];

      payload.forEach((item) => {
        if(item.name === "UV Index ") { 
          param = item.name.substr(0,item.name.length - 1);
          unit = ""; 
        } else {
          param = item.name.substr(0,item.name.indexOf("(")-1);
          unit = item.name.substr(item.name.indexOf("(")+1,item.name.indexOf(")")-item.name.indexOf("(")-1);
        }   
        paramString = param+": "+item.value+" "+unit;
        paramArr.push({ label: paramString, color: item.color });
      });

      return (
        <div className="custom-tooltip" style={{ padding: 10, border: "0.8px solid #c0c0c0", borderRadius: 4, backgroundColor: "rgba(255,255,255,0.9)", fontSize: 12, }}>
          <p>{label.replace(/-/g,'/')}</p>
          {payload.length > 0 && (
            <p style={{ color: paramArr[0].color }}>{paramArr[0].label}</p>
          )}
          {payload.length === 2 && (
            <p style={{ color: paramArr[1].color }}>{paramArr[1].label}</p>
          )}
        </div>
      );
    }
  
    return null;
  };
  
  const [activeSeries, setActiveSeries] = React.useState<Array<string>>([]);
  
  const handleLegendClick = (dataKey: any) => {
    if (activeSeries.includes(dataKey)) {
      setActiveSeries(activeSeries.filter(el => el !== dataKey));
    } else {
      setActiveSeries(prev => [...prev, dataKey]);
    }
  };

  return (
    <Grid container sx={{ height: 'calc(100vh - 400px)', width: '100%', overflow: 'hidden' }}>
      <Grid item xs={12} sm={9} md={8.5} sx={{ mt: 2, height: '100%' }} style={{ paddingLeft: 0, paddingTop: 0}}>
      {selectedParameters.length === 1 && (selectedParameters[0] === "Precipitation (mm)" || selectedParameters[0] === "Accumulated Precipitation (mm)") && (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
            barCategoryGap={0.5}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={getYearMonthDay}
              domain={['auto', 'auto']}
              tick={{ fontSize: isSmallScreen ? '8px' : isMediumScreen ? '10px' : '12px' }}
            />
            {firstMetric && renderYAxis(firstMetric, "left")}
            <Tooltip 
              wrapperStyle={{ fontSize: isSmallScreen ? '10px' : isMediumScreen ? '12px' : '14px' }}
              content={<CustomTooltip active={false} payload={[]} label={""}/>}
            />
            <Legend 
              wrapperStyle={{ fontSize: isSmallScreen ? '10px' : isMediumScreen ? '12px' : '14px' }}
              onClick={props => handleLegendClick(props.dataKey)}
            />
            {firstMetric && (
              <Bar
                hide={activeSeries.includes(firstMetric)}
                yAxisId="left"
                type="monotone"
                dataKey={firstMetric}
                fill="#0000FF"
              />
            )}
          </BarChart>
        </ResponsiveContainer>
        )}
      {selectedParameters.length === 2 && selectedParameters.indexOf("Precipitation (mm)") > 0 && selectedParameters.indexOf("Accumulated Precipitation (mm)") > 0 && (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
            barCategoryGap={0.5}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={getYearMonthDay}
              domain={['auto', 'auto']}
              tick={{ fontSize: isSmallScreen ? '8px' : isMediumScreen ? '10px' : '12px' }}
            />
            {firstMetric && renderYAxis(firstMetric, "left")}
            {secondMetric && renderYAxis(secondMetric, "right")}
            <Tooltip 
              wrapperStyle={{ fontSize: isSmallScreen ? '10px' : isMediumScreen ? '12px' : '14px' }}
              content={<CustomTooltip active={false} payload={[]} label={""}/>}
            />
            <Legend 
              wrapperStyle={{ fontSize: isSmallScreen ? '10px' : isMediumScreen ? '12px' : '14px' }}
              onClick={props => handleLegendClick(props.dataKey)}
            />
            {firstMetric && (
              <Bar
                hide={activeSeries.includes(firstMetric)}
                yAxisId="left"
                type="monotone"
                dataKey={firstMetric}
                fill="#0000FF"
              />
            )}
            {secondMetric && (
              <Bar
                hide={activeSeries.includes(secondMetric)}
                yAxisId="right"
                type="monotone"
                dataKey={secondMetric}
                fill="#FF0000"
              />
            )}
          </BarChart>
        </ResponsiveContainer>
        )}

      {selectedParameters.length === 2 && (selectedParameters.indexOf("Precipitation (mm)") > -1 || selectedParameters.indexOf("Accumulated Precipitation (mm)") > -1) && (
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
            barCategoryGap={0.5}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={getYearMonthDay}
              domain={['auto', 'auto']}
              tick={{ fontSize: isSmallScreen ? '8px' : isMediumScreen ? '10px' : '12px' }}
            />
            {firstMetric && renderYAxis(firstMetric, "left")}
            {secondMetric && renderYAxis(secondMetric, "right")}
            <Tooltip 
              wrapperStyle={{ fontSize: isSmallScreen ? '10px' : isMediumScreen ? '12px' : '14px' }}
              content={<CustomTooltip active={false} payload={[]} label={""}/>}
            />
            <Legend 
              wrapperStyle={{ fontSize: isSmallScreen ? '10px' : isMediumScreen ? '12px' : '14px' }}
              onClick={props => handleLegendClick(props.dataKey)}
            />
            {firstMetric && (firstMetric === "Precipitation (mm)" || firstMetric === "Accumulated Precipitation (mm)") && (
              <Bar
                hide={activeSeries.includes(firstMetric)}
                yAxisId="left"
                type="monotone"
                dataKey={firstMetric}
                fill="#0000FF"
              />
            )}
            {firstMetric && firstMetric !== "Precipitation (mm)" && firstMetric !== "Accumulated Precipitation (mm)" && (
              <Line
                hide={activeSeries.includes(firstMetric)}
                yAxisId="left"
                type="monotone"
                dataKey={firstMetric}
                stroke="#0000FF"
                strokeWidth={2}
              />
            )}    
            {secondMetric && (secondMetric === "Precipitation (mm)" || secondMetric === "Accumulated Precipitation (mm)") && (
              <Bar
                hide={activeSeries.includes(secondMetric)}
                yAxisId="right"
                type="monotone"
                dataKey={secondMetric}
                fill="#FF0000"
              />
            )}
            {secondMetric && secondMetric !== "Precipitation (mm)" && secondMetric !== "Accumulated Precipitation (mm)" && (
              <Line
              hide={activeSeries.includes(secondMetric)}
              yAxisId="right"
                type="monotone"
                dataKey={secondMetric}
                stroke="#FF0000"
                strokeWidth={2}
              />
            )}    

          </ComposedChart>
        </ResponsiveContainer>
        )}


        {selectedParameters.length > 0 && selectedParameters.indexOf("Precipitation (mm)") === -1 && selectedParameters.indexOf("Accumulated Precipitation (mm)") === -1 && (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={getYearMonthDay}
              domain={['auto', 'auto']}
              tick={{ fontSize: isSmallScreen ? '8px' : isMediumScreen ? '10px' : '12px' }}
            />
            {firstMetric && renderYAxis(firstMetric, "left")}
            {secondMetric && renderYAxis(secondMetric, "right")}
            <Tooltip 
              wrapperStyle={{ fontSize: isSmallScreen ? '10px' : isMediumScreen ? '12px' : '14px' }}
              content={<CustomTooltip active={false} payload={[]} label={""}/>}
            />
            <Legend 
              wrapperStyle={{ fontSize: isSmallScreen ? '10px' : isMediumScreen ? '12px' : '14px' }}
              onClick={props => handleLegendClick(props.dataKey)}
            />
            {firstMetric && (
              <Line
                hide={activeSeries.includes(firstMetric)}
                yAxisId="left"
                type="monotone"
                dataKey={firstMetric}
                stroke="#0000FF"
                strokeWidth={2}
              />
            )}
            {secondMetric && (
              <Line
                hide={activeSeries.includes(secondMetric)}
                yAxisId="right"
                type="monotone"
                dataKey={secondMetric}
                stroke="#FF0000"
                strokeWidth={2}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
        )}

        {selectedParameters.length === 0 && (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={getYearMonthDay}
              domain={['auto', 'auto']}
              tick={{ fontSize: isSmallScreen ? '8px' : isMediumScreen ? '10px' : '12px' }}
            />
            {firstMetric && renderYAxis(firstMetric, "left")}
            {secondMetric && renderYAxis(secondMetric, "right")}
            <Tooltip 
              wrapperStyle={{ fontSize: isSmallScreen ? '10px' : isMediumScreen ? '12px' : '14px' }}
              content={<CustomTooltip active={false} payload={[]} label={""}/>}
            />
            <Legend 
              wrapperStyle={{ fontSize: isSmallScreen ? '10px' : isMediumScreen ? '12px' : '14px' }}
            />
            {firstMetric && (
              <Line
                yAxisId="left"
                type="monotone"
                dataKey={firstMetric}
                stroke="#0000FF"
              />
            )}
            {secondMetric && (
              <Line
                yAxisId="right"
                type="monotone"
                dataKey={secondMetric}
                stroke="#FF0000"
              />
            )}
          </LineChart>
        </ResponsiveContainer>
        )}
      </Grid>


      <Grid item xs={12} sm={3} md={3.5} sx={{ mt: 3, maxHeight: '100%', overflowY: 'auto' }} style={{ paddingLeft: 16, paddingTop: 0}}>
        <FormGroup>
          {uniqueParameters?.map((parameter) => (
            <FormControlLabel
              key={parameter}
              control={
                <Checkbox
                  checked={selectedParameters.includes(parameter)}
                  onChange={() => handleCheckboxChange(parameter)}
                  name={parameter}
                  sx={{ transform: 'scale(0.75)', padding: '3px 9px 3px 0', }}
                />
              }
              label={
                <Typography sx={{ 
                    fontSize: { xs: 'calc(10px*0.75)', sm: 'calc(12px*0.75)', md: 'calc(14px*0.75)', lg: '16px' },
                  }}>
                  {parameter === "UV Index " ? parameter : parameter.substring(0, parameter.indexOf('(')-1)}
                </Typography>
              }
            />
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default WeatherLineChart;