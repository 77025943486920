import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './auth-config';
import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import '@fontsource/poppins';

Providers.globalProvider = new Msal2Provider({
  clientId: 'REPLACE_WITH_CLIENTID',
});

// Instantiate MSAL outside to prevent re-renders
const msalInstance = new PublicClientApplication(msalConfig);

const InitializeApp = () => {

  useEffect(() => {
    const initializeActiveAccount = async () => {
      const activeAccount = msalInstance.getActiveAccount();
      const allAccounts = msalInstance.getAllAccounts();

      if (!activeAccount && allAccounts && allAccounts.length > 0) {
        msalInstance.setActiveAccount(allAccounts[0]);
      }
    };

    initializeActiveAccount();

    msalInstance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
        console.log('Active account set:', account);
      }
    });
    
  }, []);

  return <App instance={msalInstance} />;
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
      <InitializeApp />
  </React.StrictMode>
);

reportWebVitals();
