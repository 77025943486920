import ActivitySummary from "../organisms/activitySummary";
import { Grid, Typography, SelectChangeEvent} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../atom/dropdown";

const ReportPage = () => {

  const {
    control,
    setValue,
  } = useForm({});


  let ReportTypes = [{ "id": 1, "reportType": "Activity Summary Report" }];
  
  setValue("reportTypeId", ReportTypes[0]?.id || "");

  const handlerReportTypeChange = (event: SelectChangeEvent<string | number>) => {
    setValue("reportTypeId", event.target.value);
  };


  return (
    <>
      <Grid container>
        <Grid container>
          <Grid item xs={2.5} sx={{ p: 2, pb: 0 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Reports
            </Typography>
          </Grid>        
          <Grid item xs={9.5} sx={{ pr: 1 }}>
            <Controller
              name="reportTypeId"
              control={control}
              render={({ field }) => (
              <Dropdown
                label={""}
                name="reportTypeId"
                value={field.value || ""}
                items={ReportTypes?.map((rt) => ({
                  value: rt.id,
                  label: rt.reportType
                }))}
                disabled={true}
                    onChange={(event) => {
                      handlerReportTypeChange(event);
                      field.onChange(event);
                    }}
                    sx={{ width: 280 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        <Grid item xs={12} margin={2}>
          <ActivitySummary></ActivitySummary>
        </Grid>
      </Grid>
    </>      
  );
};

export default ReportPage;
