import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Grid,
  Chip,
  Box,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DynamicFormDialog from "../molecules/dialog";
import CancelIcon from "@mui/icons-material/Cancel";
import { z } from "zod";

const MAX_FILE_SIZE = Number(process.env.REACT_APP_MAX_FILE_SIZE) || 10 * 1024 * 1024;

const noteSchema = z.object({
  Title: z.string().min(1, "Title is required"),
  Description: z.string().min(1, "Description is required"),
  Attachment: z.string().optional(),
});

interface Document {
  noteDocumentId?: number;
  documentId: number;
  name: string;
  attachment: string;
  extention: string;
  documentLink: string;
  documentTypeId: number;
  documentTypeName: string;
  createdDate: string;
}

interface ActivityNotesDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (note: {
    Name: string | null;
    Title: string;
    Description: string;
    Attachment: string | null;
    Extention: string | null;
  }) => void;
  editingNote?: {
    noteId?: number;
    title: string;
    description: string;
    documents?: Document[];
  } | null;
  editingIndex?: number | null;
}

const ActivityNotesDialog: React.FC<ActivityNotesDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  editingNote,
  editingIndex
}) => {
  const [noteTitle, setNoteTitle] = useState("");
  const [noteDescription, setNoteDescription] = useState("");
  const [attachment, setAttachment] = useState<File | null>(null);
  const [existingAttachmentInfo, setExistingAttachmentInfo] = useState<Document | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen && editingNote) {
      setNoteTitle(editingNote.title);
      setNoteDescription(editingNote.description);
      if (editingNote.documents && editingNote.documents.length > 0) {
        setExistingAttachmentInfo(editingNote.documents[0]); // Take the first document
      } else {
        setExistingAttachmentInfo(null);
      }
    } else if (!isOpen) {
      resetForm();
    }
  }, [isOpen, editingNote]);

  const resetForm = () => {
    setNoteTitle("");
    setNoteDescription("");
    setAttachment(null);
    setExistingAttachmentInfo(null);
  };

  const handleAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        setAttachment(null);
        setExistingAttachmentInfo(null);
      } else {
        setAttachment(file);
        setExistingAttachmentInfo(null);
      }
    }
  };

  const convertFileToBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString().split(",")[1] || "");
      reader.onerror = (error) => reject(error);
    });

  const handleNoteSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      let finalAttachment: string | null = null;
      let finalExtention: string | null = null;
      let finalName: string | null = null;

      if (attachment) {
        finalAttachment = await convertFileToBase64(attachment);
        finalExtention = attachment.name.split('.').pop() || "";
        finalName = attachment.name;
      } else if (existingAttachmentInfo) {
        finalAttachment = existingAttachmentInfo.attachment;
        finalExtention = existingAttachmentInfo.extention;
        finalName = existingAttachmentInfo.name;
      }

      const newNote = {
        Name: finalName,
        Title: noteTitle,
        Description: noteDescription,
        Attachment: finalAttachment,
        Extention: finalExtention,
      };

      noteSchema.parse({
        Title: noteTitle,
        Description: noteDescription,
      });

      onSubmit(newNote);
      handleClose();
    } catch (error) {
      console.error("Error submitting note: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleRemoveAttachment = () => {
    setAttachment(null);
    setExistingAttachmentInfo(null);
  };

  const formContent = (
    <form onSubmit={handleNoteSubmit}>
      <DialogContent dividers sx={{ pt: 1, pb: 1 }}>
        <Grid container spacing={1} sx={{ padding: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Title"
            value={noteTitle}
            onChange={(e) => setNoteTitle(e.target.value)}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label="Description"
            value={noteDescription}
            onChange={(e) => setNoteDescription(e.target.value)}
            sx={{ mb: 2 }}
            required
          />
          <Button variant="contained" component="label" fullWidth sx={{ mb: 2 }}>
            {(attachment || existingAttachmentInfo) ? "Replace Attachment" : "Upload Attachment"}
            <input
              type="file"
              hidden
              onChange={handleAttachmentChange}
              accept=".pdf,.xls,.xlsx,.csv,.doc,.docx,.png,.jpg,.jpeg,.tif,.tiff,.svg,.psd"
            />
          </Button>
          {(attachment || existingAttachmentInfo) && (
            <Box sx={{ width: '100%', mb: 2 }}>
              <Chip
                label={
                  attachment 
                    ? attachment.name 
                    : existingAttachmentInfo?.name || "Attachment"
                }
                onDelete={handleRemoveAttachment}
                deleteIcon={<CancelIcon />}
                sx={{ width: '100%' }}
              />
            </Box>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<SaveIcon />}
          disabled={isLoading}
        >
          {editingNote ? "Update" : "Save"}
          {isLoading && (
            <CircularProgress color="inherit" size={"15px"} sx={{ ml: 2 }} />
          )}
        </Button>
      </DialogActions>
    </form>
  );

  return (
    <DynamicFormDialog
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={undefined}
      title={editingNote ? "Edit Note" : "Add Note"}
      formContent={formContent}
    />
  );
};

export default ActivityNotesDialog;