import React, { useEffect, useState } from "react";
import { Container, Paper, Box, Button } from "@mui/material";
import logo from "../../../logo.svg";
import UserDetails from "./userDetails";
import VerifyEmail from "./verifyEmailForm";
import { useMsal } from "@azure/msal-react";

interface SignUpProps {
  onCancelClick: () => void;
}

const SignUp: React.FC<SignUpProps> = ({ onCancelClick }) => {
  const [isVerified, setIsVerified] = useState(false);
  const [email, setEmail] = useState<string>("");
  const { instance } = useMsal();
  
  useEffect(() => {
    instance.setActiveAccount(null);
  }, [instance]);

  const handleVerificationSuccess = (verifiedEmail: string) => {
    setEmail(verifiedEmail);
    setIsVerified(true);
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        pt: isVerified ? "5vh" : "30vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 2,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          width: "400px",
        }}
      >
        <Button
          size="small"
          onClick={onCancelClick}
          sx={{
            width: "50px",
            display: "flex",
            alignItems: "left",
            justifyContent: "left",
            alignContent: "left",
          }}
        >
          Cancel
        </Button>
        <Box component="img" src={logo} alt="logo" sx={{ height: 50, mb: 2 }} />

        {!isVerified ? (
          <VerifyEmail onVerificationSuccess={handleVerificationSuccess} />
        ) : (
          <UserDetails email={email} />
        )}
      </Paper>
    </Container>
  );
};

export default SignUp;
