import React, { useState, useEffect, useRef } from "react";
import { Autocomplete, TextField, IconButton, Box, CircularProgress } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import LocationOnIcon from "@mui/icons-material/LocationOn";

interface GoogleAutoSearchBarProps {
  initialLocation?: {
    address: string;
    coordinates: { lat: number; lng: number };
    components: { street: string; city: string; postalCode: string };
  };
  onLocationChange: (location: {
    address: string;
    coordinates: { lat: number; lng: number };
    components: { street: string; city: string; postalCode: string };
  }) => void;
}

const GoogleAutoSearchBar: React.FC<GoogleAutoSearchBarProps> = ({ initialLocation, onLocationChange }) => {
  const [inputValue, setInputValue] = useState<string>(initialLocation?.address || "");
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);

  useEffect(() => {
    if (!autocompleteService.current) {
      autocompleteService.current = new google.maps.places.AutocompleteService();
    }
  }, []);

  useEffect(() => {
    if (initialLocation) {
      setInputValue(initialLocation.address);
    }
  }, [initialLocation]);

  const fetchAddressDetails = async (
    query: { lat: number; lng: number } | { placeId: string }
  ): Promise<{
    address: string;
    coordinates: { lat: number; lng: number };
    components: { street: string; city: string; postalCode: string };
  } | null> => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
      let url;

      // Determine if the query is for coordinates or place_id
      if ("lat" in query && "lng" in query) {
        const { lat, lng } = query;
        url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
      } else if ("placeId" in query) {
        const { placeId } = query;
        url = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${apiKey}`;
      } else {
        throw new Error("Invalid query parameters");
      }

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Failed to fetch address details");
      }

      const data = await response.json();
      const addressComponents = data.results[0]?.address_components;

      const streetNumber = addressComponents?.find((comp) =>
        comp.types.includes("street_number")
      )?.long_name || "";

      const streetName = addressComponents?.find((comp) =>
        comp.types.includes("route")
      )?.long_name || "";

      const suburb = addressComponents?.find((comp) =>
        comp.types.includes("sublocality") || comp.types.includes("sublocality_level_2")
      )?.long_name || "";

      const street = `${streetNumber} ${streetName}, ${suburb}`.trim();

      const city = addressComponents?.find((comp) =>
        comp.types.includes("locality")
      )?.long_name || "JHB";

      const postalCode = addressComponents?.find((comp) =>
        comp.types.includes("postal_code")
      )?.long_name || "0000";

      const address = data.results[0]?.formatted_address || "";
      const coordinates = {
        lat: data.results[0]?.geometry.location.lat,
        lng: data.results[0]?.geometry.location.lng,
      };

      return {
        address,
        coordinates,
        components: { street, city, postalCode },
      };
    } catch (error) {
      console.error("Error fetching address details:", error);
      return null;
    }
  };

  const handleInputChange = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setInputValue(newValue);
    if (!autocompleteService.current || newValue === "") {
      setSuggestions([]);
      return;
    }
    autocompleteService.current.getPlacePredictions(
      {
        input: newValue,
        componentRestrictions: { country: "ZA" },
      },
      (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          setSuggestions(results);
        } else {
          setSuggestions([]);
        }
      }
    );
  };
  

  const handleSuggestionSelected = async (
    event: React.SyntheticEvent<Element, Event>,
    value: string | google.maps.places.AutocompletePrediction | null,
    reason: string
  ) => {
    if (value && typeof value !== "string") {
      setInputValue(value.description);

      const addressDetails = await fetchAddressDetails({ placeId: value.place_id });

      if (addressDetails) {
        onLocationChange(addressDetails);
      }
    } else if (typeof value === "string") {
      setInputValue(value);
      onLocationChange({
        address: value,
        coordinates: { lat: 0, lng: 0 },
        components: { street: "", city: "", postalCode: "" },
      });
    }
  };

  const handleAddressPinClick = async () => {
    setIsLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          const addressDetails = await fetchAddressDetails({ lat: latitude, lng: longitude });

          if (addressDetails) {
            setInputValue(addressDetails.address);
            onLocationChange(addressDetails);
          }

          setIsLoading(false);
        },
        (error) => {
          console.error("Error fetching location:", error);
          setIsLoading(false);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setIsLoading(false);
    }
  };

  return (
    <Autocomplete
      fullWidth
      value={inputValue}
      onChange={handleSuggestionSelected}
      inputValue={inputValue}
      onInputChange={(event, newValue) => handleInputChange(event, newValue)}
      options={suggestions}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      freeSolo
      disablePortal
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search for a place"
          variant="outlined"
          size="small"
          margin="dense"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {params.InputProps.endAdornment}
                <Box ml={1} />
                <IconButton onClick={handleAddressPinClick} edge="end" style={{ color: "darkgreen" }}>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={"15px"} />
                  ) : (
                    <LocationOnIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default GoogleAutoSearchBar;