import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Grid,
  styled,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Tooltip,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import FormSection from "../molecules/DynamicFormSection";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
  updateUserProfile,
  getUserProfile,
  setAzureUserId,
  terminateFarmersFriendAccount,
} from "../../services/api-ffm-service";
import { UserProfile } from "../../models/userProfile.interface";
import { useConfig } from "../../context/ConfigContextTest";
import { DeleteIcon } from "lucide-react";
import { useMsal } from "@azure/msal-react";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = z.object({
  givenName: z.string().min(1, "Given Name is required"),
  surname: z.string().min(1, "Surname is required"),
  displayName: z.string().min(1, "Display Name is required"),
  mobilePhone: z
    .string()
    .length(10, "Mobile Phone must be exactly 10 digits")
    .regex(phoneRegExp, "Please enter a valid Mobile Phone"),
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const UserProfileForm = () => {
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [terminateModalOpen, setTerminateModalOpen] = useState(false);
  const [confirmTerminateOpen, setConfirmTerminateOpen] = useState(false);
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const { selectedOrganization, clearAllConfig } = useConfig();


  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      givenName: userProfile?.givenName ?? "",
      surname: userProfile?.surname ?? "",
      displayName: userProfile?.displayName ?? "",
      mobilePhone: userProfile?.mobilePhone ?? "",
    },
  });

  useEffect(() => {
    if (selectedOrganization?.azureUserId) {
      setAzureUserId(selectedOrganization.azureUserId);
    }

    const fetchUserProfile = async () => {
      const data = await getUserProfile();
      if (data) {
        if (selectedOrganization) {
          const mobileContact = selectedOrganization.contactDetail[0]?.contacts.find(
            contact => contact.type === "Mobile"
          );
          if (mobileContact && !data.mobilePhone) {
            data.mobilePhone = mobileContact.details;
          }
        }
        
        setUserProfile(data);
        reset({
          givenName: data.givenName || "",
          surname: data.surname || "",
          displayName: data.displayName || "",
          mobilePhone: data.mobilePhone || "",
        });
      }
    };
    
    fetchUserProfile();
  }, [reset, modalOpen, selectedOrganization]);

  const handleFormSubmit = async (data) => {
    setIsLoading(true);
    try {
      data.azureUserId = userProfile ? userProfile?.userId : "";
      const response = await updateUserProfile(data);
      console.log("Profile Updated:", response);
      setModalOpen(false);
    } catch (error) {
      console.error("Failed to update user profile:", error);
    }
    setIsLoading(false);
  };
  const handleClose = () => {
    reset();
    setModalOpen(false);
  };
  const fieldDefinitions = {
    profileDetails: [
      { id: "givenName", label: "Name", type: "text" },
      { id: "surname", label: "Last Name", type: "text" },
      { id: "displayName", label: "Display Name", type: "text" },
      { id: "mobilePhone", label: "Contact Number", type: "text" },
    ],
  };

  const handleTerminateAccount = () => {
    setTerminateModalOpen(true);
  };

  const handleConfirmTermination = async () => {
    setIsLoading(true);
    try {
      await terminateFarmersFriendAccount(selectedOrganization?.partyId ?? 0);
      // Handle successful termination (e.g., logout, redirect)
      setIsLoading(true);
      clearAllConfig();
      instance.setActiveAccount(null);
      
      const logoutRequest = {
        account: activeAccount,
        postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
        onRedirectNavigate: (url) => {
          return false;
        },
      };
      instance.logoutRedirect(logoutRequest).finally(() => {
        setIsLoading(false);
      });
    } catch (error) {
      console.error("Failed to terminate account:", error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Tooltip title="Profile">
        <IconButton
          sx={{
            width: "30px",
            height: "30px",
          }}
          onClick={() => setModalOpen(true)}
          color="primary"
        >
          <ManageAccountsIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <StyledDialog
        onClose={handleClose}
        open={modalOpen}
        aria-labelledby="update-profile-dialog"
      >
        <DialogTitle id="update-profile-dialog">Update Profile</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <DialogContent dividers>
            <Grid container spacing={2} sx={{ padding: 2 }}>
              <FormSection
                fields={fieldDefinitions.profileDetails}
                control={control}
                errors={errors}
                columns={1}
                title=""
              />
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<SaveIcon />}
              disabled={isLoading}
              sx={{  }}
            >
              Update
              {isLoading && (
                <CircularProgress
                  color="inherit"
                  size={"15px"}
                  sx={{ ml: 2 }}
                />
              )}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleTerminateAccount}
              startIcon={<DeleteIcon />}
              disabled={isLoading}
              sx={{ marginLeft: 'auto' }}
            >
              Terminate Account
            </Button>
          </DialogActions>
        </form>
      </StyledDialog>

      {/* Termination Warning Dialog */}
      <Dialog open={terminateModalOpen} onClose={() => setTerminateModalOpen(false)}>
        <DialogTitle>Account Termination Warning</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            By terminating your Farmers Friend account, you agree to the following actions:
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            • Your personal information will be removed from our database; however, we will retain your mapping data for sector analytics.
          </Typography>
          <Typography variant="body2">
            • Any Farmers Friend connections you have with third parties will no longer be accessible to you or the other parties involved.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTerminateModalOpen(false)}>Cancel</Button>
          <Button 
            color="error" 
            onClick={() => {
              setTerminateModalOpen(false);
              setConfirmTerminateOpen(true);
            }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      {/* Final Confirmation Dialog */}
      <Dialog open={confirmTerminateOpen} onClose={() => setConfirmTerminateOpen(false)}>
        <DialogTitle>Confirm Account Termination</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to terminate your account? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmTerminateOpen(false)}>Cancel</Button>
          <Button 
            color="error" 
            onClick={handleConfirmTermination}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                Terminating Account
                <CircularProgress color="inherit" size={15} sx={{ ml: 1 }} />
              </>
            ) : (
              'Terminate Account'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
