import React, { useRef, useState, useEffect } from "react";
import { Grid, Typography, Button, AlertColor } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import KanbanBoard from "../organisms/kanbanBoard/kanbanOverview";
import { fetchData, useFetchData } from "../../hooks/useFetchData";
import { createActivity, getActivities, updateActivity } from "../../services/api-ffm-service";
import ActivityDialog from "../organisms/activityDialog";
import GenericConfirmDialog from "../organisms/genericConfirmDialog";
import { handleApiResponse } from "../../utils/Utilities";
import CustomSnackbar from "../atom/CustomSnackbar";
import { useConfig } from "../../context/ConfigContextTest";

const Activities: React.FC = () => {
  const [activities, setActivities] = useState<any[]>([]);
  const [formOpen, setFormOpen] = useState(false);
  const { selectedOrganization } = useConfig();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const snackbarRef = useRef<{ handleOpen: (title: string, msg: string | null, sev: AlertColor) => void }>(null);

  useFetchData(getActivities, setActivities, setIsLoading, [
    selectedOrganization?.organizationId ?? 0,
  ]);

  useEffect(() => {

    console.log("rendering activities");
    console.log(activities);
  }, [activities]);

  const handleOpenForm = () => {
    setFormOpen(true);
    setSelectedActivity(null);
  };

  const handleCloseForm = () => {
    setSelectedActivity(null);
    setFormOpen(false);
    setConfirmOpen(false);
    setIsLoading(false);
  };

  const handleDelete = () => {
    setSelectedActivity(selectedActivity);
    setConfirmOpen(true);
  };

  const handleSubmit = async (formData: any) => {
    setIsLoading(true);
    formData.partyId = selectedOrganization?.partyId;
    formData.statusId = formData.activityStatusId;
    let response;

    const endDate = new Date(formData.endDate);
    const today = new Date();
    if (formData.statusId === 12 && endDate > today) {
      if (snackbarRef.current) {
        snackbarRef.current.handleOpen(
          "Failed!",
          "Activity status can not be set to DONE if the end date is future dated.",
          "warning"
        );
      }
    } 
    else 
    {
      if (selectedActivity) {
        try {
          response = await updateActivity(formData);
        } catch (error) {
          console.error("Error updating activity:", error);
        }
      } else {
        try {
          response = await createActivity(formData);
        } catch (error) {
          console.error("Error creating activity:", error);
        }
      }
    }

    fetchData(getActivities, setActivities, setIsLoading, [
      selectedOrganization?.organizationId ?? 0,
    ]);

    handleCloseForm();
    
    handleApiResponse(response, showSnackbar);
  };

  const showSnackbar = (title, message, severity) => {
    if (snackbarRef.current) {
      snackbarRef.current.handleOpen(title, message, severity);
    }
  };

  return (
    <>
      <CustomSnackbar ref={snackbarRef} />

      {!isLoading && (
        <>
          <Grid container sx={{ p: 2, pb: 0 }}>
            <Grid item xs={2.5}>
              <Typography variant="h6" sx={{  mb: 1 }}>
                Activity Manager
              </Typography>
            </Grid>  
            <Grid item xs={9.5}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => handleOpenForm()}
                  color="primary"
                >
                  Add Activity
                </Button>
            </Grid>  
          </Grid>

           
          <Grid container spacing={2} padding={"10px"}>
            <Grid item xs={12}>
              <ActivityDialog
                isOpen={formOpen}
                onClose={handleCloseForm}
                onSubmit={handleSubmit}
                formData={selectedActivity}
                handleDelete={handleDelete}
              />
              
              <KanbanBoard></KanbanBoard>
              <GenericConfirmDialog
                open={confirmOpen}
                onCancel={() => setConfirmOpen(false)}
                onConfirm={() => {}}
                title="Confirm Deletion"
                content="Are you sure you want to delete this activity?"
              />
            </Grid>
          </Grid>
         
          </>
      )}
    </>
  );
};

export default Activities;


// import React, { useRef, useState } from "react";
// import { Grid, Typography, AlertColor, Button } from "@mui/material";
// import ActivityTimeline from "../organisms/ActivityTimeline";
// import { useFetchData } from "../../hooks/useFetchData";
// import { createActivity, getActivities, updateActivity } from "../../services/api-ffm-service";
// import ActivityDialog from "../organisms/activityDialog";
// import GenericConfirmDialog from "../organisms/genericConfirmDialog";
// import { handleApiResponse } from "../../utils/Utilities";
// import CustomSnackbar from "../atom/CustomSnackbar";
// import { useConfig } from "../../context/ConfigContextTest";

// import { Add as AddIcon } from "@mui/icons-material";

// const Activities: React.FC = () => {
//   const [activities, setActivities] = useState<any[]>([]);
//   const [formOpen, setFormOpen] = useState(false);
//   const { selectedOrganization, mappedfields } = useConfig();
//   const [confirmOpen, setConfirmOpen] = useState(false);
//   const [selectedActivity, setSelectedActivity] = useState<any | null>(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const snackbarRef = useRef<{ handleOpen: (title: string, msg: string | null, sev: AlertColor) => void }>(null);

//   // Initial data fetch using the hook
//   useFetchData(getActivities, setActivities, setIsLoading, [
//     selectedOrganization?.organizationId ?? 0,
//   ]);

//   // Separate function for fetching data
//   const fetchActivities = async () => {
//     setIsLoading(true);
//     try {
//       const response = await getActivities(selectedOrganization?.organizationId ?? 0);
//       setActivities(response);
//     } catch (error) {
//       console.error("Error fetching activities:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleOpenForm = () => {
//     setFormOpen(true);
//     setSelectedActivity(null);
//   };

//   const handleCloseForm = () => {
//     setSelectedActivity(null);
//     setFormOpen(false);
//     setConfirmOpen(false);
//     setIsLoading(false);
//   };

//   const handleDelete = () => {
//     setSelectedActivity(selectedActivity);
//     setConfirmOpen(true);
//   };

//   const handleActivityClick = (activity) => {
//     setSelectedActivity(activity);
//     setFormOpen(true);
//   };

//   const handleSubmit = async (formData: any) => {
//     setIsLoading(true);
//     formData.partyId = selectedOrganization?.partyId;
//     formData.statusId = formData.activityStatusId;
//     let response;

//     try {
//       if (selectedActivity) {
//         response = await updateActivity(formData);
//       } else {
//         response = await createActivity(formData);
//       }

//       // Fetch updated activities after successful submission
//       await fetchActivities();
//       handleCloseForm();
//       handleApiResponse(response, showSnackbar);
//     } catch (error) {
//       console.error("Error handling activity submission:", error);
//       setIsLoading(false);
//     }
//   };

//   const showSnackbar = (title, message, severity) => {
//     if (snackbarRef.current) {
//       snackbarRef.current.handleOpen(title, message, severity);
//     }
//   };

//   return (
//     <>
//       <CustomSnackbar ref={snackbarRef} />

//       {!isLoading && (
//         <>
//           <Grid container sx={{ p: 2, pb: 0 }}>
//             <Grid item xs={2.5}>
//               <Typography variant="h6" sx={{  mb: 1 }}>
//                 Activity Manager
//               </Typography>
//             </Grid>  
//             <Grid item xs={9.5}>
//                 <Button
//                   variant="contained"
//                   startIcon={<AddIcon />}
//                   onClick={() => handleOpenForm()}
//                   color="primary"
//                 >
//                   Add Activity
//                 </Button>
//             </Grid>  
//           </Grid>

//           <Grid container spacing={2} padding={"10px"}>
//             <Grid item xs={12}>
//               <ActivityTimeline
//                 activities={activities}
//                 fields={mappedfields}
//                 onActivityClick={handleActivityClick}
//                 onAddActivity={handleOpenForm}
//               />
              
//               <ActivityDialog
//                 isOpen={formOpen}
//                 onClose={handleCloseForm}
//                 onSubmit={handleSubmit}
//                 formData={selectedActivity}
//                 handleDelete={handleDelete}
//               />
              
//               <GenericConfirmDialog
//                 open={confirmOpen}
//                 onCancel={() => setConfirmOpen(false)}
//                 onConfirm={() => {}}
//                 title="Confirm Deletion"
//                 content="Are you sure you want to delete this activity?"
//               />
//             </Grid>
//           </Grid>
//         </>
//       )}
//     </>
//   );
// };

// export default Activities;