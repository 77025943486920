import React, { useEffect, useMemo } from 'react';
import { Button, Grid, MenuItem } from '@mui/material';
import { Controller, useWatch } from 'react-hook-form';
import TextBox from '../atom/textBox';
import MultiSelect from '../molecules/MultiSelect';

const FieldComponent = ({ field, control, errors, setValue, handleOpenDialog, handleFieldChange, cultivarOptions, productCostPerHectare, isApplicationCategory }) => {
  const fieldValue = useWatch({
    control,
    name: field.id,
    defaultValue: field.id === 'cultivar' || field.type === "multiSelect" ? [] : '',
  });

  const applicationType = useWatch({
    control,
    name: 'applicationType',
    defaultValue: '',
  });

  const fertilizerType = useWatch({
    control,
    name: 'fertilizer_type',
    defaultValue: '',
  });
  
  // const filteredOptions = useMemo(() => {
  //   if (field.id === 'quantityType') {
  //     // If not in Application category, return original options without filtering
  //     if (!isApplicationCategory) {
  //       // Clear any invalid values when switching categories
  //       const currentValue = fieldValue;
  //       if (currentValue && !field.options.some(opt => opt.id === currentValue)) {
  //         setTimeout(() => setValue(field.id, ''), 0);
  //       }
  //       return field.options;
  //     }

  //     // Application category filtering logic
  //     switch (applicationType) {
  //       case 'applicationType_chemical':
  //         return field.options.filter(option => option.id === 'hectare_l');
  //       case 'applicationType_fertilizer':
  //         return field.options.filter(option => ['hectare_l', 'hectare_kg'].includes(option.id));
  //       case 'applicationType_soilConditioner':
  //         return field.options.filter(option => option.id === 'hectare_kg');
  //       default:
  //         return field.options;
  //     }
  //   }
  //   return field.options;
  // }, [field.id, field.options, applicationType, isApplicationCategory, fieldValue, setValue]);

  useEffect(() => {
    if (applicationType === 'applicationType_fertilizer' && !fertilizerType) {
      setValue('fertilizer_type', 'fertilizer_type_organic');
    }
  }, [applicationType, fertilizerType, setValue]);

  const isUOMDisabled = useMemo(() => {
    if (field.id === 'quantityType') {
      if (!isApplicationCategory) {
        return false;
      }
      return ['applicationType_chemical', 'applicationType_soilConditioner'].includes(applicationType);
    }
    return field.disabled;
  }, [field.id, field.disabled, applicationType, isApplicationCategory]);

  const filteredOptions = useMemo(() => {
    if (field.id === 'quantityType') {
      if (!isApplicationCategory) {
        return field.options;
      }

      switch (applicationType) {
        case 'applicationType_chemical':
          return field.options.filter(option => option.id === 'hectare_l');
        case 'applicationType_fertilizer':
          return field.options.filter(option => ['hectare_l', 'hectare_kg'].includes(option.id));
        case 'applicationType_soilConditioner':
          return field.options.filter(option => option.id === 'hectare_kg');
        default:
          return field.options;
      }
    }
    return field.options;
  }, [field.id, field.options, applicationType, isApplicationCategory]);

  const isCostPerHectareDisabled = useMemo(() => {
    return applicationType === 'applicationType_chemical';
  }, [applicationType]);

  useEffect(() => {
    if (field.id === 'quantityType' && isApplicationCategory) {
      let defaultUOM = '';
      switch (applicationType) {
        case 'applicationType_chemical':
          defaultUOM = 'hectare_l';
          break;
        case 'applicationType_soilConditioner':
          defaultUOM = 'hectare_kg';
          break;
        case 'applicationType_fertilizer':
          defaultUOM = 'hectare_kg';
          break;
        default:
          break;
      }

      if (defaultUOM) {
        setValue('quantityType', defaultUOM);
      }
    }

    if (field.id === 'cost' && applicationType === 'applicationType_chemical') {
      setValue('cost', productCostPerHectare);
    }
  }, [applicationType, field.id, setValue, productCostPerHectare, isApplicationCategory]);
  
  const renderNestedFields = () => {
    if (field.options && Array.isArray(field.options)) {
      const selectedOption = field.options.find(option => option.id === fieldValue);
      if (selectedOption && selectedOption.children) {
        return selectedOption.children.map(childField => (
          <FieldComponent
            key={childField.id}
            field={childField}
            control={control}
            errors={errors}
            setValue={setValue}
            handleOpenDialog={handleOpenDialog}
            handleFieldChange={handleFieldChange}
            cultivarOptions={cultivarOptions}
            productCostPerHectare={productCostPerHectare}
            isApplicationCategory={isApplicationCategory}
          />
        ));
      }
    }
    return null;
  };

  return (
    <>
      <Grid item xs={6}>
        <Controller
          name={field.id}
          control={control}
          render={({ field: { onChange, value, onBlur, ref } }) => {
            if (field.id === 'cultivar' || field.type === "multiSelect") {
              return (
                <MultiSelect
                  label={field.label}
                  value={Array.isArray(value) ? value : []}
                  onChange={(newValue) => {
                    onChange(newValue);
                    handleFieldChange(field.id, newValue);
                  }}
                  options={field.id === 'cultivar' ? cultivarOptions : field.options}
                  error={!!errors[field.id]}
                  helperText={errors[field.id]?.message}
                  fieldId={field.id}
                  sx={{ mb: 1 }}
                />
              );
            } else if (field.type === "select") {
              return (
                <TextBox
                  select
                  id={field.id}
                  label={field.label}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    handleFieldChange(field.id, e.target.value);
                  }}
                  onBlur={onBlur}
                  error={!!errors[field.id]}
                  helperText={errors[field.id]?.message}
                  inputRef={ref}
                  fullWidth
                  sx={{ mb: 1 }}
                  disabled={isUOMDisabled}
                >
                  {Array.isArray(filteredOptions) ? (
                    filteredOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          {option.label}
                        </div>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No Options Available</MenuItem>
                  )}
                </TextBox>
              );
            } else if (field.type === "button") {
              return (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleOpenDialog(field.dialogId)}
                  sx={{ mt: 2 }}
                >
                  {field.label}
                </Button>
              );
            } else if (field.type === "decimal" || field.type === "currency" || field.type === "wholeNumber") {
              return (
                <TextBox
                  label={field.label}
                  value={value}
                  onChange={onChange}
                  type={field.type}
                  error={!!errors[field.id]}
                  helperText={errors[field.id]?.message}
                  fullWidth
                  disabled={field.id === 'cost' && isCostPerHectareDisabled}
                />
              );
            } else {
              return (
                <TextBox
                  id={field.id}
                  label={field.label}
                  placeholder={field.placeholder}
                  type={field.type}
                  value={value}
                  min={field.min}
                  max={field.max}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors[field.id]}
                  disabled={field.disabled || (field.id === 'cost' && isCostPerHectareDisabled)}
                  helperText={errors[field.id]?.message}
                  readOnly={field.readOnly}
                  sx={{ mb: 0 }}
                  inputRef={ref}
                />
              );
            }
          }}
        />
      </Grid>
      {renderNestedFields()}
    </>
  );
};



const DynamicFieldRenderer = ({
  fields,
  control,
  errors,
  handleFieldChange,
  handleOpenDialog,
  watch,
  setValue,
  cultivarOptions,
  productCostPerHectare,
  isApplicationCategory,
}) => {
  const selectedApplicationType = watch('applicationType');

  const { filteredFields, showAddProductButton } = useMemo(() => {
    const filteredFields = fields.map(field => {
      if (field.id === 'applicationType') {
        return {
          ...field,
          options: field.options.map(option => {
            if (option.id === 'applicationType_chemical') {
              return {
                ...option,
                children: option.children.filter(child => child.type !== 'button')
              };
            }
            return option;
          })
        };
      }
      return field;
    });

    const showAddProductButton = selectedApplicationType === 'applicationType_chemical';

    return { filteredFields, showAddProductButton };
  }, [fields, selectedApplicationType]);

  return (
    <>
      {filteredFields.map((field) => (
        <FieldComponent
          key={field.id}
          field={field}
          control={control}
          errors={errors}
          setValue={setValue}
          handleOpenDialog={handleOpenDialog}
          handleFieldChange={handleFieldChange}
          cultivarOptions={cultivarOptions}
          productCostPerHectare={productCostPerHectare}
          isApplicationCategory={isApplicationCategory}
        />
      ))}
      {showAddProductButton && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => handleOpenDialog('ActivityProductsDialog')}
          sx={{ mt: 2, ml: 2 }}
        >
          ADD PRODUCT(S)
        </Button>
      )}
    </>
  );
};

export default React.memo(DynamicFieldRenderer);